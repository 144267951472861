<template>
  <div v-if="columns.length" :class="$style.component">
    <content-columns :columns="columns" :class="columnsClass" />
  </div>
</template>
<script>
import ContentColumns from '@/components/content-columns'

export default {
  components: { ContentColumns },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    layout() {
      return this.item.row_layout ? this.item.row_layout.value : ''
    },
    columns() {
      return this.item.content_columns
    },
    columnCount() {
      return this.columns.length
    },
    columnsClass() {
      return [
        'count-' + this.columnCount,
        this.columnCount === 2 && this.layout ? 'extend-' + this.layout : ''
      ]
    }
  }
}
</script>

<style lang="scss" module>
.component {
  @media (min-width: $small) {
    &:not(:first-child) {
      @include border-regular(top);
      margin-top: $blank-line * 0.5;
      padding-top: $blank-line * 1.5;
    }
  }
}
</style>
