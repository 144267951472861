<template>
  <div :class="$style.view" v-if="page.data">
    <header :class="$style.header">
      <h2 :class="$style.title" v-html="page.title" />
      <router-link :to="rootRoute" :class="$style.close" />
    </header>
    <form :class="$style.main">
      <div :class="$style.inner">
        <div>
          <h3 :class="$style.subtitle" v-html="page.data.patterns.title" />
          <ul :class="$style.patterns">
            <li>
              <label>
                <input type="checkbox" value="all" />
                <span>All</span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" value="index" />
                <span>Index</span>
              </label>
            </li>
            <li
              v-for="pattern in page.data.patterns.data"
              :key="pattern.id"
              :class="$style.pattern"
            >
              <label>
                <input type="checkbox" name="patterns" :value="pattern.id" />
                <span>
                  <span :class="$style['pattern-label']">
                    <span :class="$style['pattern-number']">
                      {{ pad(pattern.pattern_nr, 2) }}
                    </span>
                    <span :class="$style['pattern-title']">
                      {{ pattern.title }}
                    </span>
                  </span>
                </span>
              </label>
            </li>
          </ul>
        </div>
        <div>
          <h3 :class="$style.subtitle" v-html="page.data.projects.title" />
          <ul :class="$style.projects">
            <li>
              <label>
                <input type="checkbox" value="all" />
                <span>All</span>
              </label>
            </li>
            <li>
              <label>
                <input type="checkbox" value="index" />
                <span>Index</span>
              </label>
            </li>
            <li
              v-for="project in page.data.projects.data"
              :key="project.id"
              :class="$style.project"
            >
              <label>
                <input type="checkbox" name="projects" :value="project.id" />
                <span>
                  <span :class="$style['project-label']">
                    <span :class="$style['project-title']">
                      {{ project.title }}
                    </span>
                    <span :class="$style['project-location']">
                      {{ project.location }}
                    </span>
                  </span>
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
      <div :class="$style.actions">
        <button :class="$style.submit" type="submit" disabled>Print</button>
      </div>
    </form>
  </div>
</template>

<script>
import { padNumber } from '@/mixins/pad-number'
import axios from 'axios'

export default {
  mixins: [padNumber],
  data() {
    return {
      page: [],
      loading: false,
      error: null
    }
  },
  computed: {
    rootRoute() {
      return this.$route.meta.root ? this.$route.meta.root.url : '/'
    }
  },
  methods: {
    fetchData() {
      this.page = []
      this.loading = true
      this.error = null
      axios
        .get(`print`)
        .then(response => {
          this.page = response.data
          this.loading = false
        })
        .catch(error => {
          alert(error)
        })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss" module>
.view {
  @include page-gutter(padding, right, left);
  @include page-gutter-extended(padding, right, left);
  @include page-gutter-vertical(padding, top, bottom);

  position: relative;
  z-index: 2;
  min-height: 100%;
  margin-top: var(--panel-bar-height);
  background-color: $grey-fill;

  @media (min-width: $large) {
    margin-top: 0;
    margin-left: var(--panel-bar-height);
  }
}

.header {
  @extend %header-margin;

  display: flex;
  justify-content: space-between;
}

.title,
.subtitle {
  @extend %fs-page-title;
}

.close {
  margin-left: 1em;

  &::after {
    @extend %ff-symbols;
    @extend %fs-page-title;

    display: inline-block;
    content: '\e001';
  }
}

.main {
  @include border-regular(top);
  @include page-gutter-vertical(padding, top);
}

.inner {
  @media (min-width: $large) {
    display: flex;
    align-items: flex-start;
    margin-left: -$gutter-large;

    > * {
      flex: 1;
      max-width: $text-width;
      padding-left: $gutter-large;
    }
  }
}

.subtitle {
  //@extend %fs-page-title;
  margin-bottom: $blank-line / 3;
}

.patterns,
.projects {
  @include page-gutter(margin, bottom);

  li {
    @include border-regular(bottom);
    @extend %fs-large-body;

    padding: 0.5em 0; // TODO: temp value
  }

  label {
    //display: block;
    @include input-icon($unchecked: '\e003', $checked: '\e002', $indent: 1.5em);
  }
}

.pattern-label {
  display: flex;
}

.pattern-number {
  min-width: 1.6em;
}

.project-label {
  span {
    display: block;
  }
}

.actions {
  @media (min-width: $large) {
    //margin-left: auto;
    //width: calc(50% - #{$gutter-large});
  }
}

.submit {
  width: 100%;
  padding: 1em;
  color: white;
  background: $grey-text;
  border: none;
  appearance: none;

  &[disabled] {
    &::after {
      content: ' (disabled)';
    }
  }
}
</style>
