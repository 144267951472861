<template>
  <div :class="$style.component">
    <a
      :href="href"
      :class="$style.link"
      @click.prevent="shareLink"
      rel="bookmark"
    >
      <span :class="$style.label">Share</span>
      <span :class="$style.icon" />
    </a>
  </div>
</template>

<script>
export default {
  computed: {
    href() {
      return location.href
    }
  },
  methods: {
    shareLink() {
      window.prompt('Copy the following URL to share this page:', this.href)
    }
  }
}
</script>

<style lang="scss" module>
// .component {}

.link {
  // @extend %hover-link;

  display: flex;
}

.label {
  @extend %fs-tool-bar;

  display: none;
  padding-right: 0.3em;

  @media (min-width: $large) {
    display: block;
  }
}

.icon {
  &::after {
    @extend %ff-symbols;
    @extend %fs-tool-bar-icon;

    display: inline-block;
    content: '\e013';
  }
}
</style>
