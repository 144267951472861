<template>
  <div :class="$style.component">
    <router-link :to="prevItem.url" :class="$style.prev" />
    <span :class="$style.count">
      {{ pad(currentIndex + 1, 2) }}&#8202;/&#8202;{{ pad(count, 2) }}
    </span>
    <router-link :to="nextItem.url" :class="$style.next" />
  </div>
</template>

<script>
import { padNumber } from '@/mixins/pad-number'

export default {
  props: {
    itemId: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    }
  },
  mixins: [padNumber],
  computed: {
    currentIndex() {
      return this.items.findIndex(item => {
        return item.id === this.itemId
      })
    },
    prevIndex() {
      return this.currentIndex === 0
        ? this.items.length - 1
        : this.currentIndex - 1
    },
    prevItem() {
      return this.items[this.prevIndex]
    },
    nextIndex() {
      return (this.currentIndex + 1) % this.items.length
    },
    nextItem() {
      return this.items[this.nextIndex]
    },
    count() {
      return this.items.length
    }
  }
}
</script>

<style lang="scss" module>
.component {
  //@include page-gutter-vertical(margin, bottom);
  display: flex;
  // justify-content: space-between;
  align-items: center;
  // margin-bottom: $gutter / 2;
  margin-left: -$gutter;

  > * {
    padding-left: $gutter;
  }

  @media (min-width: $large) {
    // margin-bottom: $gutter-large / 2;
  }
}

.prev,
.next {
  @extend %fs-tool-bar-icon;
  // @extend %hover-link;

  &::before {
    @extend %ff-symbols;

    display: inline-block;
    content: '\e006';
  }
}

.prev {
  &::before {
    transform: scaleX(-1);
  }
}

.count {
  @extend %fs-tool-bar;

  display: none;

  @media (min-width: $large) {
    display: block;
  }
}
</style>
