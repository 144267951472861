<template>
  <div :class="$style.component">
    <ul :class="$style.categories">
      <li
        v-for="(category, index) in projectsByCategory"
        :key="`category-${index}`"
        :class="$style.category"
      >
        <div :class="$style['category-title']" v-html="category.title" />
        <ul :class="$style.list">
          <ProjectsIndexItem
            v-for="project in category.items"
            :key="project.id"
            :project="project"
            :class="$style.item"
          />
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import ProjectsIndexItem from '@/components/projects-index-item'

export default {
  components: { ProjectsIndexItem },
  props: {
    projects: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },
  computed: {
    projectsByCategory() {
      let pbc = {}
      this.projects.forEach(project => {
        const catId = project.climate_zone
        const cat = this.categories.find(category => category.id === catId)

        // Check if category was already added to pbc
        if (!(catId in pbc)) {
          pbc[catId] = {
            title: cat.title,
            items: []
          }
        }

        // Add project to category
        pbc[catId].items.push(project)
      })
      return pbc
    }
  }
}
</script>

<style lang="scss" module>
.category {
  padding-top: $gutter / 2;
  // @include border-regular(top);
  margin-bottom: $gutter;

  @media (min-width: $large) {
    display: flex;
    padding-top: 0;
    margin-bottom: 0;

    &:first-child {
      > * {
        padding-top: 0;
        border-top: 0;

        > :first-child {
          padding-top: 0;
          border-top: 0;
        }
      }
    }
  }
}

.category-title {
  @extend %fs-column-title;

  margin-bottom: 0.5em;

  @media (min-width: $large) {
    @include border-regular(top);

    padding-top: 0.4em;
    margin-right: 2.7rem;
    margin-bottom: 0;
  }
}

.category-title,
.list {
  @media (min-width: $large) {
    width: 50%;
  }
}
</style>
