<template>
  <div :class="classes.layout">
    <portal-target v-if="!isDesktop" name="overlay" :class="$style.portal" />

    <div :class="classes.home">
      <base-image :class="$style.cover" :image="coverImages" :sizes="sizes" />
      <div :class="$style.preorder">
        <a
          href="https://www.park-books.com/index.php?pd=pb&lang=en&page=books&view=co&book=1466&booktype=order_1_releasedate&subject=1&artist=all&author=all&do=addtocart&cartitem=1466"
          target="_blank"
        >
          <img src="@/assets/images/pre-order.svg" />
        </a>
      </div>
      <PartnerLogos :class="$style.logos" fill="#ffffff" />
    </div>

    <div :class="classes.tool">
      <router-link :to="panelUrl(routes[0])">
        <header :class="$style.header">
          <h1 :class="$style.title">
            {{ pageTitle }}<br /><span :class="$style.claim"
              >A Design Instrument</span
            >
          </h1>
          <!-- <div :class="$style.subtitle">
            Introduction to the<br />
            Design Instrument
          </div> -->
        </header>

        <!-- <svg :class="$style.shape" viewBox="0 0 1000 1000">
          <polygon :class="$style.triangle" points="500,14 986,986 14,986" />
        </svg> -->
      </router-link>
      <div :class="classes.view" ref="toolView">
        <transition
          :name="transitionName"
          mode="out-in"
          @before-enter="beforeEnter"
          @after-enter="afterEnter"
          @before-leave="beforeLeave"
          @after-leave="afterLeave"
        >
          <keep-alive>
            <slot name="tool" />
          </keep-alive>
        </transition>
      </div>
    </div>
    <div :class="classes.patterns">
      <router-link :to="panelUrl(routes[1])">
        <header :class="$style.header">
          <h2 :class="$style.title">{{ routes[1].title }}</h2>
          <!-- <div :class="$style.subtitle">
            Exploration of the 28<br />
            Architectural Patterns
          </div> -->
        </header>

        <!-- <svg :class="$style.shape" viewBox="0 0 1000 1000">
          <circle :class="$style.circle" cx="500" cy="500" r="493" />
        </svg> -->
      </router-link>
      <div id="patterns-view" :class="classes.view" ref="patternsView">
        <transition
          :name="transitionName"
          mode="out-in"
          @before-enter="beforeEnter"
          @after-enter="afterEnter"
          @before-leave="beforeLeave"
          @after-leave="afterLeave"
        >
          <keep-alive>
            <slot name="patterns" />
          </keep-alive>
        </transition>
      </div>
    </div>
    <div :class="classes.projects">
      <router-link :to="panelUrl(routes[2])">
        <header :class="$style.header">
          <h2 :class="$style.title">{{ routes[2].title }}</h2>
          <!-- <div :class="$style.subtitle">
            Collection of the most<br />
            Inspiring Environments
          </div> -->
        </header>

        <!-- <svg :class="$style.shape" viewBox="0 0 1000 1000">
          <rect
            :class="$style.rectangle"
            x="7"
            y="7"
            width="986"
            height="986"
          />
        </svg> -->
      </router-link>
      <div id="projects-view" :class="classes.view" ref="projectsView">
        <transition
          :name="transitionName"
          mode="out-in"
          @before-enter="beforeEnter"
          @after-enter="afterEnter"
          @before-leave="beforeLeave"
          @after-leave="afterLeave"
        >
          <keep-alive>
            <slot name="projects" />
          </keep-alive>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import EventBus from '@/event-bus'
import BaseImage from '@/components/base-image'
import PartnerLogos from '@/components/partner-logos'

export default {
  components: { BaseImage, PartnerLogos },
  props: {
    rootTemplate: {
      type: String,
      default: undefined
    }
  },
  metaInfo() {
    return {
      title: this.$route.meta.title
    }
  },
  data() {
    return {
      inTransition: false,
      currentSlot: '',
      sliderState: {},
      subpage: false,
      timer: undefined
    }
  },
  computed: {
    ...mapState(['coverImages', 'isDesktop', 'pageTitle', 'routes']),
    classes() {
      return {
        layout: [
          this.$style.layout,
          this.inTransition ? 'in-transition' : '',
          this.activeSlot ? 'panel-expanded' : 'panels-closed'
        ],
        home: [
          this.$style.panel,
          this.$style.home
          // this.activeSlot === 'home' ? 'is-active' : 'not-active'
        ],
        tool: [
          this.$style.panel,
          this.$style.tool,
          this.activeSlot === 'tool' ? 'is-active' : 'not-active'
        ],
        patterns: [
          this.$style.panel,
          this.$style.patterns,
          this.activeSlot === 'patterns' ? 'is-active' : 'not-active'
        ],
        projects: [
          this.$style.panel,
          this.$style.projects,
          this.activeSlot === 'projects' ? 'is-active' : 'not-active'
        ],
        view: [
          this.$style.view,
          this.sliderState.isZoomed ? 'slider-zoomed' : ''
        ]
      }
    },
    sizes() {
      return '(orientation: portrait) 150vw, 100vw'
    },
    activeSlot() {
      return this.rootTemplate
    },
    transitionName() {
      // TODO: use different transition for subpages
      return this.subpage ? 't-subpage' : 't-panel'
      // return 't-panel'
    }
  },
  methods: {
    beforeEnter() {
      //this.$root.$emit('scrollBeforeEnter')
    },
    afterEnter() {
      this.inTransition = false
      EventBus.$emit('after-enter')
      //this.currentSlot = this.rootTemplate
    },
    beforeLeave() {
      this.inTransition = true
      //EventBus.$emit('before-leave')
    },
    afterLeave(el) {
      const slot = el.getAttribute('data-slot')
      const ref = this.$refs[`${slot}View`]
      ref.scrollTo(0, 0)
      //this.$root.$emit('scrollBeforeEnter')
    },
    panelUrl(route) {
      return route.url === this.$route.path ? '/' : route.url
    },
    goHome() {
      this.$router.push('/')
    }
  },
  mounted() {
    EventBus.$on('slider-zoom', payload => {
      // TODO: scroll position not correctly recalled when slider closes
      // TODO: reset scroll position based on child that emitted the event
      this.$refs.patternsView.scrollTop = 0
      this.$refs.projectsView.scrollTop = 0

      /*
      if (payload.isZoomed) {
        this.$refs.projectsView.scrollTop = 0
      } else {
        this.$refs.projectsView.scrollTop = payload.viewOffset
      }
      */

      //console.log(payload)
      this.sliderState = payload
    })
  },
  watch: {
    /*
    rootTemplate() {
      console.log(this.rootTemplate)
    }
    */
    $route() {
      //console.log(this.subpage)

      // if subpage is false, we are on a panel page
      // if subpage is true, we are on a subpage

      // TODO: could this also be computed property?
      //console.log(this.$route.meta.template)
      let template = this.$route.meta.template
      if (
        template === 'tool' ||
        template === 'patterns' ||
        template === 'projects'
      ) {
        this.subpage = false
      } else {
        this.subpage = true
      }

      // if subpage is true, we are going to a subpage
      // if subpage is false, we are going to a panel

      //console.log(this.subpage)
    }
  }
}
</script>

<style lang="scss">
// @include t-fade($name: 't-panel', $duration: 1000);

.t-panel-enter-active {
  transition: opacity 500ms ease 1000ms;
}

.t-panel-leave-active {
  transition: opacity 500ms ease;
}

.t-panel-enter,
.t-panel-leave-to {
  opacity: 0;
}

.t-subpage-enter-active,
.t-subpage-leave-active {
  transition: opacity 500ms ease;
  // transition: transform 500ms ease;
}

.t-subpage-enter,
.t-subpage-leave-to {
  opacity: 0;
  // transform: translateX(-100%);
}
</style>

<style lang="scss" module>
$duration: 225ms;
$delay: $duration * 2;

.layout {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &:global(.panels-closed) {
    > * {
      &.home {
        flex-grow: 1;
      }

      > a {
        // display: block;
        flex: 1;
        height: 100%;
      }
    }

    .view {
      pointer-events: none;
    }

    .header,
    .shape {
      visibility: visible;
      opacity: 1;
      transition: opacity $duration ease $delay,
        visibility $duration ease $delay;
    }

    .tool {
      background-color: $yellow;
    }
  }

  &:global(.panel-expanded) {
    // .header {
    //   opacity: 0;
    //   visibility: hidden;
    //   transition: opacity $duration ease, visibility $duration ease;
    // }

    > * {
      .subtitle {
        display: none;
      }
    }
  }

  > * {
    position: relative;
    display: flex;
    // flex-basis: 100%;
    flex-basis: auto;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 1;
    // transition: flex-grow $delay ease $delay, background-color 150ms ease;
    // NOTE: Use "all" instead of "flex-grow" as Safari 13.1 doesn’t play the flex transition otherwise
    transition: all $delay ease $delay, background-color 150ms ease;

    &:global(.is-active) {
      flex-grow: 1;
    }

    > a {
      position: relative;
      // z-index: 1;
      display: block;
      min-height: var(--panel-bar-height);
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    &.tool {
      > a {
        min-height: var(--tool-panel-bar-height);
      }
    }
  }

  .portal {
    position: absolute;
  }

  @media (min-width: $large) {
    flex-direction: row;

    > * {
      > a {
        min-width: var(--panel-bar-height);
        min-height: 0;
      }

      &.tool {
        > a {
          min-width: var(--tool-panel-bar-height);
          min-height: 0;
          transition: min-width $duration ease $delay;
        }
      }
    }

    &:global(.panels-closed) {
      > * {
        &:hover {
          // flex-grow: 1.1; // TODO: If activated, it causes problems when closing the panels as the mouse is hovering over a panel right away
        }
      }
    }

    &:global(.panel-expanded) {
      > * {
        // TODO: removing the delay from flex-grow breaks transition
        // transition: flex-grow $delay ease, background-color 150ms ease;
        // NOTE: Use "all" instead of "flex-grow" as Safari 13.1 doesn’t play the flex transition otherwise
        transition: all $delay ease, background-color 150ms ease;
        // transition: flex-grow $delay ease $delay, background-color 150ms ease;

        > a {
          width: var(--panel-bar-height);
          height: 100%;
        }

        &:global(.is-active) {
          > a {
            width: var(--active-panel-bar-height);
          }
        }

        &:global(.not-active) {
          > a {
            // Override min-width of .tool > a
            min-width: var(--panel-bar-height);
            min-height: 0;
            transition: min-width $duration ease;
          }

          // .header {
          //   position: absolute;
          //   bottom: 0;
          //   left: 0.15em;
          //   padding: 0;
          //   visibility: visible;
          //   opacity: 1;
          //   transform: rotate(-90deg);
          //   transform-origin: top left;

          //   h1,
          //   h2 {
          //     white-space: nowrap;
          //   }
          // }

          // &:hover {
          //   flex-grow: 0.01;
          // }
        }
      }

      .claim {
        opacity: 0;
        transition: opacity $duration ease;
      }
    }
  }

  @media (min-width: $xxlarge) {
    &:global(.panel-expanded) {
      > * {
        &:global(.is-active) {
          > a {
            // width: $gutter-large * 2; // TODO: this adds sudden jump when switching panels
          }
        }
      }
    }
  }
}

.panel {
  overflow: hidden;
  background-color: $grey-fill;
  box-shadow: $panel-shadow;

  &:global(.is-active) {
    // .header {
    //   opacity: 1;
    //   visibility: visible;
    //   transition: opacity $duration ease $delay,
    //     visibility $duration ease $delay;
    // }
  }

  &.tool {
    ::selection {
      background-color: rgba($yellow, 0.99);
    }
  }

  &.patterns {
    ::selection {
      background-color: rgba($blue, 0.99);
    }
  }

  &.projects {
    ::selection {
      background-color: rgba($red, 0.99);
    }
  }

  &:global(.is-active) {
    &.tool {
      background-color: $yellow;
    }

    &.patterns {
      background-color: $blue;
    }

    &.projects {
      background-color: $red;
    }
  }

  @media #{$mqHover} {
    &:hover {
      &.tool {
        background-color: $yellow;

        .subtitle {
          color: $yellow-shadow;
        }
      }

      &.patterns {
        background-color: $blue;

        .subtitle {
          color: $blue-shadow;
        }
      }

      &.projects {
        background-color: $red;

        .subtitle {
          color: $red-shadow;
        }
      }
    }
  }

  // > a {}
}

.cover {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.preorder {
  position: absolute;
  top: 0;
  right: 0;
  width: 15rem;
  height: auto;

  @media (min-width: $large) {
    top: $gutter;
    right: $gutter * 2;
    width: 19rem;
  }
}

.logos {
  display: none;

  @media (min-width: $large) {
    position: absolute;
    bottom: $gutter * 2;
    left: $gutter * 2;
    display: block;
    width: 30rem;
  }
}

// .header,
.shape {
  visibility: hidden;
  opacity: 0;
  transition: opacity $duration ease, visibility $duration ease;
}

.header {
  @include page-gutter(padding, right, left);
  // @include page-gutter-vertical(padding, top, bottom);

  position: absolute;
  padding-top: $gutter / 1.1;
  padding-bottom: $gutter / 1.1;
  // transition: opacity $duration ease $delay, visibility $duration ease $delay;
  // transition: opacity $duration ease, visibility $duration ease;

  @media (min-width: $large) {
    // position: absolute;
    bottom: 0;
    left: 0.15em;
    padding-right: 0;
    // padding: 0;
    padding-left: 0;
    visibility: hidden;
    visibility: visible;
    opacity: 0;
    opacity: 1;
    transform: rotate(-90deg);
    transform-origin: top left;

    h1,
    h2 {
      position: relative;
      top: $gutter / -1.1 / 2;
      white-space: nowrap;
    }

    h1 {
      // Move title "down" as it consists of two lines
      left: calc((#{$gutter} / 1.1 * -2) - 1.05em);
    }

    h2 {
      left: $gutter / 1.1 * -2;
    }
  }
}

.claim {
  transition: opacity $duration ease $delay;
}

.title,
.subtitle {
  @extend %fs-site-title;
}

.subtitle {
  color: $grey-text;
  transition: color 150ms ease;
}

.shape {
  // svg
  @include page-gutter(padding, right, bottom, left);

  position: absolute;
  top: 7em;
  display: block;
  width: 100%;
  height: auto;
  pointer-events: none;
  user-select: none;
  // opacity: 0;
  // visibility: hidden;
  // transition: opacity $duration ease, visibility $duration ease;

  @media (min-width: $large) {
    top: 8.5em;
    width: auto;
    height: calc(100% - 8.5em);
  }

  @media (min-width: $xlarge) {
    top: 7em;
    height: calc(100% - 7em);
  }

  @media (min-width: $xxlarge) {
    top: 9em;
    height: calc(100% - 9em);
  }
}

.triangle,
.circle,
.rectangle {
  fill: transparent;
  stroke: $black;
  stroke-width: 14;

  @media (min-width: $large) {
    stroke-width: 16;
  }
}

.view {
  // position: relative; // Keeps SVG below content
  position: absolute;
  flex: 1;
  // z-index: 2;
  width: 100%;
  height: calc(100% - var(--active-panel-bar-height));
  margin-top: var(--active-panel-bar-height);
  overflow: auto;
  transition: background-color $delay ease;
  -webkit-overflow-scrolling: touch;

  &:global(.slider-zoomed) {
    overflow: hidden;
  }

  :global(.is-active) & {
    background-color: $grey-fill;
    transition: background-color $delay ease $delay * 2;
  }

  .tool & {
    height: calc(100% - var(--tool-active-panel-bar-height));
    margin-top: var(--tool-active-panel-bar-height);
  }

  @media (min-width: $large) {
    width: calc(100% - var(--active-panel-bar-height));
    height: 100%;
    margin-top: 0;
    margin-left: var(--active-panel-bar-height);

    .tool & {
      // width: calc(100% - var(--tool-active-panel-bar-height));
      height: 100%;
      margin-top: 0;
      // margin-left: var(--tool-active-panel-bar-height);
    }
  }
}

:global(.in-transition) {
  .view {
    position: absolute;
    overflow: hidden;
  }
}

// .tool {
//   background-color: $yellow;
// }

// .patterns {
//   background-color: $blue;
// }

// .projects {
//   background-color: $red;
// }
</style>
