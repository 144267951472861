<template>
  <div :class="$style.component">
    <view-paging :item-id="itemId" :items="items" :class="$style.paging" />
    <save-item :page="page" />
    <share-item />
  </div>
</template>

<script>
import SaveItem from '@/components/save-item'
import ShareItem from '@/components/share-item'
import ViewPaging from '@/components/view-paging'

export default {
  components: {
    SaveItem,
    ShareItem,
    ViewPaging
  },
  props: {
    itemId: {
      type: Number,
      required: true
    },
    items: {
      type: Array,
      required: true
    },
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    item() {
      return this.items.find(item => {
        return item.id === this.itemId
      })
    }
  }
}
</script>

<style lang="scss" module>
.component {
  display: flex;
  margin-left: -$gutter;
  margin-bottom: $blank-line / 2;

  > * {
    padding-left: $gutter;
  }
}

.paging {
  margin-right: auto;
}
</style>
