<template>
  <li :class="classes.component" @click="zoomIn">
    <div :class="$style.image">
      <base-image :image="item.project_image[0]" :sizes="sizes" />
    </div>
    <div :class="$style.references" v-if="hasReferences">
      <ul :class="classes.patterns" v-if="item.patterns">
        <li
          v-for="(pattern, index) in item.patterns"
          :key="`pattern-${index}`"
          :class="$style.pattern"
        >
          <router-link :to="pattern.url" :class="$style['pattern-link']">
            <div
              @mouseenter="onPatternHover = true"
              @mouseleave="onPatternHover = false"
              :class="$style['pattern-number']"
            >
              <div :class="$style['pattern-number-inner']">
                <span>{{ pad(pattern.pattern_nr, 2) }}</span>
              </div>
            </div>
            <div :class="$style['pattern-title']" v-html="pattern.title" />
          </router-link>
        </li>
      </ul>
      <div :class="$style.project" v-if="item.project">
        <router-link :to="item.project.url" :class="$style['project-link']">
          <!-- <div :class="$style['project-icon']" /> -->
          <div :class="$style['project-title']">
            {{ item.project.title }}<br />
            {{ item.project.location }}
          </div>
        </router-link>
      </div>
    </div>
  </li>
</template>

<script>
import BaseImage from '@/components/base-image'
import { padNumber } from '@/mixins/pad-number'
import EventBus from '@/event-bus'

export default {
  components: { BaseImage },
  mixins: [padNumber],
  props: {
    isZoomed: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true
    },
    layout: {
      type: String,
      default: 'auto'
    }
  },
  data() {
    return {
      onPatternHover: false
    }
  },
  computed: {
    classes() {
      return {
        component: [
          this.$style.component,
          this.isZoomed ? this.$style['is-zoomed'] : this.$style['not-zoomed'],
          this.onPatternHover ? 'on-pattern-hover' : '',
          this.layout === 'grid' ? this.$style['layout-grid'] : ''
        ],
        patterns: [
          this.$style.patterns,
          this.referencesCount > 3 ? this.$style['in-columns'] : ''
        ]
      }
    },
    hasReferences() {
      // TODO: returns error because item.patterns is undefined
      return (
        ((this.item.patterns && this.item.patterns.length) ||
          (this.item.project && this.item.project.length)) &&
        this.isZoomed
      )
      // return this.isZoomed
    },
    referencesCount() {
      if (this.item.patterns) return this.item.patterns.length
      if (this.item.project) return this.item.project.length
      return 0
    },
    sizes() {
      if (this.isZoomed) {
        return '100vw'
      } else {
        if (this.layout === 'grid') {
          // eslint-disable-next-line
          return '(min-width: 1680px) 27.08vw, (min-width: 1024px) 25.49vw, calc(100vw - 3rem)'
        } else {
          // eslint-disable-next-line
          return '(min-width: 1680px) 41.67vw, (min-width: 1024px) 39.84vw, calc(100vw - 3rem)'
        }
      }
    }
  },
  methods: {
    zoomIn() {
      this.$emit('zoom-in')
    }
  }
}
</script>

<style lang="scss" module>
.image {
  flex-grow: 1;
  min-height: 0;

  .is-zoomed & {
    width: 100%;
    height: 100%;
    padding: 0 $gutter $gutter;
  }

  .not-zoomed & {
    position: relative;
    height: 0;
    padding-bottom: 66.666%; // TODO: make this dynamic based on ratio of first image
    overflow: hidden;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  @media (min-width: $large) {
    .is-zoomed & {
      padding-top: $gutter;
      padding-right: $gutter * 3;
      padding-left: $gutter * 3;
    }

    .not-zoomed.layout-grid & {
      img {
        object-position: 0 0;
      }
    }
  }

  @media (min-width: $xlarge) {
    .is-zoomed & {
      padding-right: $gutter * 4;
      padding-left: $gutter * 4;
    }
  }
}

// .component {
//   position: relative;
//   margin-bottom: $blank-line * 2.5;

//   &:global(.is-zoomed) {
//     position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 1;
//     display: flex;
//     flex-direction: column;
//     width: 100%;
//     height: 100%;
//     background-color: $black;

//     .wrapper {
//       // position: relative;
//       // width: 100%;
//       // height: 100%;
//       flex-grow: 1;
//       // height: 100%;
//       // overflow: hidden;
//       // flex: 1 1 100%;
//     }

//     .list {
//       // width: 100%;
//       height: 100%;
//     }

//     .item {
//       display: flex;
//       flex-direction: column;
//       height: 100%;
//     }

//     img {
//       flex-grow: 1;
//       width: 100%;
//       height: 100%;
//       object-fit: cover;
//     }

//     .zoom {
//       outline: 0;
//     }
//   }

//   &:global(.not-zoomed) {
//     filter: grayscale(1);
//     transition: filter 150ms ease;

//     @media #{$mqHover} {
//       &:hover {
//         filter: grayscale(0);
//       }
//     }
//   }

//   @media (min-width: $large) {
//     // margin-bottom: $blank-line;

//     // &:global(.is-zoomed) {
//     //   height: 100vh;
//     // }

//     // &:global(.not-zoomed) {}

//     &:global(.layout-grid.not-zoomed) {
//       .list {
//         display: flex;
//         flex-wrap: wrap;
//         margin-left: -$gutter;

//         > * {
//           width: 33.3333%;
//           padding-left: $gutter;
//           margin-bottom: $gutter;
//         }
//       }

//       img {
//         cursor: pointer;
//       }

//       .nav,
//       .pagination {
//         display: none;
//       }
//     }
//   }
// }

.references {
  @extend %fs-references;
  // @include page-gutter(padding, right, left);
  // @include page-gutter-vertical(padding, top, bottom);

  position: relative;
  // bottom: 0;
  // left: 0;
  z-index: 2;
  // color: $white;
  // width: 100%;
  pointer-events: none;

  @media (min-width: $large) {
    display: flex;
    // justify-content: space-between;
  }
}

.patterns {
  @include page-gutter(padding, right, left);
  // @include page-gutter-vertical(padding, bottom);
  padding-bottom: $gutter;

  @media (min-width: $large) {
    width: 75%;
    padding-right: $gutter * 3;
    padding-left: $gutter * 3;
  }

  @media (min-width: $xlarge) {
    padding-right: $gutter * 4;
    padding-left: $gutter * 4;
  }

  @media (min-width: $xxlarge) {
    &.in-columns {
      column-gap: $gutter-large;
      column-count: 2;
    }
  }
}

.pattern {
  break-inside: avoid;
}

.pattern-link {
  display: flex;
  // align-items: center;
  pointer-events: auto;

  // @media #{$mqHover} {
  //   &:hover {
  //     ~ .pattern-title {
  //       visibility: visible;
  //       opacity: 1;
  //     }
  //   }
  // }
}

.pattern-number {
  padding-right: 0.5em;
  // padding: 0.4em 0;

  // @media (min-width: $large) {
  //   padding: 0.25em 0;
  // }
}

.pattern-number-inner {
  // @include border-regular;

  // display: flex;
  // width: 2.6em;
  // height: 2.6em;
  // color: $white;
  // border-radius: 50%;
  //background-color: $blue;
  //box-shadow: 0 0 0.5em 0 rgba($black, 0.5);

  // span {
  //   margin: auto;
  // }

  // @media (min-width: $large) {
  //   width: 2.1em;
  //   height: 2.1em;
  // }
}

.pattern-title {
  // margin-left: 0.8em;
  // color: $white;
  // visibility: hidden;
  // opacity: 0;
  // transition: opacity 75ms ease, visibility 75ms ease;
}

.project {
  // @include page-gutter;
  // margin-top: 0.8em; // TODO: temp value
  // padding: 0; // Override
  // @include page-gutter(padding, right, left);
  // @include page-gutter-vertical(padding, bottom);

  padding: 0 $gutter $gutter;

  @media (min-width: $large) {
    // position: absolute;
    // right: 0;
    // bottom: 0;
    // padding-top: 0;
    padding-right: $gutter * 3;
  }
}

.project-link {
  @extend %hover-link;

  display: flex;
  align-items: center;
  pointer-events: auto;

  @media (min-width: $large) {
    max-width: 20em;
    margin-left: auto;
  }
}

.project-icon {
  position: relative;
  top: 0.175em; // Optically align with .project-title
  display: flex;
  order: 1;
  //width: 2.6em;
  //height: 2.6em;
  //border-radius: 50%;
  margin-left: 0.8em;
  //background-color: $red;
  //box-shadow: 0 0 0.5em 0 rgba($black, 0.5);
  cursor: pointer;

  &::after {
    @extend %ff-symbols;

    display: inline-block;
    // @extend %fs-page-title;
    content: '\e015';
  }

  /*
  span {
    margin: auto;
  }
  */

  /*
  @media #{$mqHover} {
    &:hover {
      ~ .project-title {
        display: block;
      }
    }
  }
  */

  @media (min-width: $large) {
    //width: 2.1em;
    //height: 2.1em;
  }
}

.project-title {
  flex: 1;
  // text-align: right;
}
</style>
