<template>
  <div :class="$style.view" v-if="page.data">
    <header :class="$style.header">
      <h2 :class="$style.title" v-html="page.title" />
    </header>
    <ul :class="$style.categories">
      <li
        v-for="category in patternsByCategory"
        :key="category.id"
        :class="$style.category"
      >
        <h3 :class="$style['category-title']">
          <span>{{ category.title }}</span>
        </h3>
        <ul :class="$style.patterns">
          <li
            v-for="pattern in category.patterns"
            :key="pattern.id"
            :class="$style.pattern"
          >
            <router-link :to="pattern.url" :class="$style.link">
              <span :class="$style['pattern-number']">
                {{ pad(pattern.pattern_nr, 2) }}
              </span>
              <span :class="$style['pattern-title']">
                {{ pattern.title }}
              </span>
            </router-link>
          </li>
        </ul>
      </li>
    </ul>

    <FooterNav />
  </div>
</template>

<script>
import FooterNav from '@/components/footer-nav'
import { padNumber } from '@/mixins/pad-number'
import RecallScrollPosition from '@/mixins/recall-scroll-position.js'
import axios from 'axios'

export default {
  components: { FooterNav },
  mixins: [padNumber, RecallScrollPosition],
  data() {
    return {
      page: [],
      loading: false,
      error: null
    }
  },
  computed: {
    patternsByCategory() {
      if (!this.page.data) return
      let items = []
      this.page.categories.forEach(category => {
        let item = category
        const categoryPatterns = this.page.data.filter(pattern => {
          return category.id === pattern.category
        })
        item.patterns = categoryPatterns
        items.push(item)
      })
      return items
    }
  },
  methods: {
    fetchData() {
      this.page = []
      this.loading = true
      this.error = null
      axios
        .get(`patterns`)
        .then(response => {
          this.page = response.data
          this.loading = false
        })
        .catch(error => {
          alert(error)
        })
    }
  },
  created() {
    this.fetchData()
  },
  activated() {
    this.recallScrollPosition(this.$parent.$refs.patternsView)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setScrollPosition', {
      name: from.name,
      pos: this.$parent.$refs.patternsView.scrollTop
    })
    next()
  }
}
</script>

<style lang="scss" module>
.view {
  @extend %view-padding;
}

.header {
  @extend %header-margin;

  display: none;

  @media (min-width: $large) {
    display: block;
  }
}

.title {
  @extend %fs-page-title;

  min-height: 2.1em;
}

.category {
  // @include border-regular(top);

  &:not(:first-child) {
    padding-top: $gutter / 2;
  }

  &:not(:last-child) {
    margin-bottom: $gutter;
  }

  @media (min-width: $large) {
    display: flex;
    margin-bottom: 0;

    &:not(:first-child) {
      padding-top: 0;
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
}

.category-title {
  @extend %fs-pattern-column-title;

  margin-bottom: 0.5em;

  @media (min-width: $large) {
    @include border-regular(top);

    width: calc(50% - #{$gutter});
    padding-top: 0.4em;
    margin-right: $gutter * 2;
    margin-bottom: 0;

    span {
      display: block;
      max-width: 9em;
    }
  }
}

.patterns {
  @media (min-width: $large) {
    flex: 1;
  }
}

.pattern {
  @include border-regular(top);
  @extend %fs-pattern-title;

  padding-top: 0.5em;
  padding-bottom: 0.45em;

  @media (min-width: $large) {
    padding-top: 0.4em;
    padding-bottom: 0.35em;
  }
}

.link {
  display: flex;
  transition: color 100ms ease;

  &::after {
    @include page-gutter(margin, left);
    @extend %ff-symbols;

    display: flex;
    align-items: center;
    // @extend %fs-page-title;
    content: '\e011';
  }

  // &:visited {}

  &:hover {
    color: $grey-border;
  }
}

.pattern-number {
  @include page-gutter(margin, right);

  @media (min-width: $xxlarge) {
    margin-right: $gutter-large * 1.5;
  }
}

.pattern-title {
  flex: 1;
}
</style>
