<template>
  <component :is="type" :item="item" :class="$style.component" />
</template>
<script>
import ContentTypeBody from '@/components/content-type-body'
import ContentTypeImages from '@/components/content-type-images'

export default {
  components: {
    ContentTypeBody,
    ContentTypeImages
  },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      // TODO: Temp workaround! Type order in this array has to match with backend!
      matrixTypes: ['body', 'images']
    }
  },
  computed: {
    type() {
      const matrixType = this.capitalizeFirstLetter(this.item.type)
      return `content-type-${matrixType}`
    }
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    }
  }
}
</script>

<style lang="scss" module>
// .component {}
</style>
