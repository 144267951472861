<template>
  <form :class="$style.component" @submit="onSubmit" ref="form">
    <button :class="$style.close" @click="close" type="button">
      <span>Filter</span>
    </button>

    <ul :class="$style.list">
      <filter-group
        v-for="group in visibleFilter"
        :key="group.id"
        :group="group"
      />
    </ul>

    <button :class="$style.clear" @click="clear" type="reset">
      Clear
    </button>
  </form>
</template>

<script>
import FilterGroup from '@/components/filter-group'
import EventBus from '@/event-bus'

export default {
  components: { FilterGroup },
  props: {
    visibleFilter: {
      type: Array,
      required: true
    }
  },
  methods: {
    onSubmit($event) {
      $event.preventDefault()
    },
    close() {
      EventBus.$emit('close-filter')
    },
    clear() {
      this.$refs.form.reset()
      this.$store.commit('clearFilter')
    }
  }
}
</script>

<style lang="scss" module>
.component {
  @include page-gutter(padding, right, left);
  @include page-gutter-vertical(padding, top, bottom);
  @extend %fs-filter-nav;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: $black;
  -webkit-overflow-scrolling: touch;

  @media (min-width: $large) {
    position: static;
    z-index: initial;
    width: auto;
    height: auto;
    padding: 0;
    overflow: initial;
    background-color: transparent;
  }
}

.close,
.clear {
  @extend %fs-filter-actions;

  display: block;
  margin-left: auto;
  color: $grey-fill;
  outline: 0;

  &::after {
    @extend %ff-symbols;

    padding-left: 0.3em;
  }
}

.close {
  &::after {
    content: '\e001';
  }

  span {
    display: none;
  }

  @media (min-width: $large) {
    display: none;
    // color: $black;

    &::after {
      content: '\e005';
    }

    span {
      display: initial;
    }
  }
}

.clear {
  margin-bottom: $gutter / 2;

  &::after {
    content: '\e001';
  }

  @media (min-width: $large) {
    color: $grey-text;
    transition: color 100ms ease;

    &:hover {
      color: $black;
    }
  }
}

.list {
  @media (min-width: $large) {
    display: flex;
    margin-left: $gutter * -1.5;
  }
}
</style>
