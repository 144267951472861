<template>
  <div :class="$style.component">
    <base-bodytext :text="item.body" :class="$style.body" />
  </div>
</template>

<script>
import BaseBodytext from '@/components/base-bodytext'

export default {
  components: { BaseBodytext },
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.component {
  margin-bottom: $blank-line;

  @media (min-width: $large) {
    margin-bottom: $blank-line * 2;
  }
}

.body {
  // @extend %fs-large-body;
  @extend %body-field;
}
</style>
