<template>
  <ul :class="$style.component">
    <li v-for="item in selection" :key="item.value" :class="$style.item">
      {{ item.title }}
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    selection: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.component {
  @include border-regular(bottom);
  padding-top: $gutter / 2;
  padding-bottom: $gutter / 2;

  @media (min-width: $large) {
    padding-top: $gutter;
    padding-bottom: $gutter;
  }
}

.item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 0.75em;
  }

  &:before {
    @extend %ff-symbols;
    content: '\e002';
  }
}
</style>
