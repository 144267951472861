<template>
  <div :class="$style.view" v-if="page.data" ref="view">
    <header :class="$style.header">
      <div :class="$style['category-title']">
        <span>{{ page.data.pattern_category.title }}</span>
      </div>
      <h2 :class="$style.title">
        <span :class="$style['pattern-number']">
          {{ pad(page.data.pattern_nr, 2) }}
        </span>
        <span :class="$style['pattern-title']">
          {{ page.title }}
        </span>
      </h2>
      <div>
        <router-link :to="rootRoute" :class="$style.close" />
      </div>
    </header>
    <tool-bar
      :item-id="page.id"
      :items="routes[1].meta.subnavigation"
      :page="page"
    />
    <section :class="$style.main">
      <div :class="$style.inner">
        <!-- <div v-if="page.data.media_id" :class="$style.graphic">
          <JwPlayerVideo
            :media-id="page.data.media_id"
            :autostart="autostart"
            :aspectRatio="aspectRatio"
          />
        </div>
        <div v-else-if="page.data.graphic[0]" :class="$style.graphic"> -->
        <div v-if="page.data.graphic[0]" :class="$style.graphic">
          <base-image :class="$style.image" :image="page.data.graphic[0]" />
          <div
            v-if="page.data.graphic[0].description"
            v-html="page.data.graphic[0].description"
            :class="$style.caption"
          />
        </div>

        <div :class="$style.columns">
          <div :class="$style['columns-inner']">
            <div :class="$style.column">
              <h3>Idea</h3>
              <base-bodytext :text="page.data.context" :class="$style.body" />
            </div>
            <div :class="$style.column">
              <h3>Tasks</h3>
              <base-bodytext
                :text="page.data.recommendation"
                :class="$style.body"
              />
            </div>
            <div :class="$style.column">
              <h3>Examples</h3>
              <base-bodytext :text="page.data.reference" :class="$style.body" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section v-if="page.samples && page.samples.length" :class="$style.samples">
      <h3 :class="$style['samples-title']">
        Repertoire
      </h3>
      <project-image-slider
        :items="page.samples"
        :class="$style.slider"
        layout="grid"
      />
    </section>
  </div>
</template>

<script>
import BaseBodytext from '@/components/base-bodytext'
import BaseImage from '@/components/base-image'
// import JwPlayerVideo from '@/components/jw-player-video'
import ToolBar from '@/components/tool-bar'
import ProjectImageSlider from '@/components/project-image-slider'
import { padNumber } from '@/mixins/pad-number'
import { mapState } from 'vuex'
import EventBus from '@/event-bus'
import axios from 'axios'

export default {
  components: {
    BaseBodytext,
    BaseImage,
    // JwPlayerVideo,
    ToolBar,
    ProjectImageSlider
  },
  mixins: [padNumber],
  data() {
    return {
      page: [],
      loading: false,
      error: null,
      autostart: 'true',
      aspectRatio: 'auto',
      sliderState: {}
    }
  },
  computed: {
    ...mapState(['routes']),
    rootRoute() {
      return this.$route.meta.root ? this.$route.meta.root.url : '/'
    }
  },
  methods: {
    fetchData() {
      this.page = []
      this.loading = true
      this.error = null
      axios
        .get(`pattern/${this.$route.meta.id}`)
        .then(response => {
          this.page = response.data
          this.loading = false
        })
        .catch(error => {
          alert(error)
        })
    },
    lockView() {
      const view = this.$refs.view
      view.style.position = 'fixed'
      view.style.zIndex = '1'
      view.style.top = this.sliderState.viewOffset + 'px'
    },
    releaseView() {
      const view = this.$refs.view
      view.style.position = ''
      view.style.zIndex = ''
      view.style.top = ''
    },
    scrollView() {
      const view = document.getElementById('patterns-view')
      this.$nextTick(() => {
        view.scrollTo(0, this.sliderState.viewOffset * -1)
      })
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    EventBus.$on('slider-zoom', payload => {
      this.sliderState = payload

      if (!this.sliderState.isZoomed) {
        this.releaseView()
        this.scrollView()
      } else if (this.sliderState.isZoomed && this.sliderState.viewOffset) {
        this.lockView()
      }
    })
  }
}
</script>

<style lang="scss" module>
.view {
  @include page-gutter(padding, right, left);
  @include page-gutter-extended(padding, right, left);
  @include page-gutter-vertical(padding, top, bottom);

  position: relative;
  // z-index: 2;
  min-height: 100%;
  // margin-top: var(--panel-bar-height);
  background-color: $white;

  @media (min-width: $large) {
    // margin-top: 1rem;
    // margin-bottom: 10rem;
    // margin-left: var(--panel-bar-height);
  }
}

.header {
  @extend %header-margin;

  display: flex;
  justify-content: space-between;
  margin-left: -$gutter;

  > * {
    padding-left: $gutter;
  }
}

.category-title {
  @extend %fs-pattern-title;

  display: none;

  @media (min-width: $large) {
    display: block;
    width: 50%;

    span {
      display: inline-block;
      max-width: 9.5em;
    }
  }
}

.title {
  @extend %fs-pattern-title;

  display: flex;
  flex: 1;
}

.pattern-number {
  @include page-gutter(margin, right);

  @media (min-width: $xxlarge) {
    margin-right: $gutter-large * 1.5;
  }
}

.close {
  // @extend %hover-link;

  margin-left: 1em;

  &::after {
    @extend %ff-symbols;
    @extend %fs-pattern-title;

    display: inline-block;
    content: '\e001';
  }

  @media (min-width: $xxlarge) {
    position: relative;
    left: $gutter * 3.2;
  }
}

.main {
  @include border-regular(top);
  @include page-gutter-vertical(padding, top);
}

.columns {
  @include border-regular(top);

  margin-bottom: $blank-line * 2;

  @media (min-width: $large) {
    margin-bottom: 0;
  }
}

.columns-inner {
  @media (min-width: $large) {
    display: flex;
    margin-left: -$gutter;

    > * {
      width: 33.333%;
      padding-left: $gutter;
    }
  }
}

.column {
  h3 {
    @extend %fs-small;

    margin-top: $blank-line * 1.5;
    margin-bottom: $blank-line / 4;
  }

  @media (min-width: $large) {
    h3 {
      margin-top: $blank-line;
      margin-bottom: $blank-line / 2;
    }
  }
}

.graphic {
  margin-bottom: $blank-line; // TODO: temp value
}

.image {
  @media (min-width: $large) {
    width: 100%;
    height: calc(100vh - 32.5rem); // TODO: temp value
    object-fit: contain;
  }
}

.caption {
  @extend %fs-small;

  margin-top: $blank-line / 4;
  text-align: right;
}

.body {
  @extend %body-field;

  @media (min-width: $large) {
    position: relative;
    top: -0.5rem; // Optically align with images
  }
}

.samples {
  @include border-regular(top);
}

.samples-title {
  @extend %fs-small;

  margin-top: $blank-line / 4;
  margin-bottom: $blank-line;

  @media (min-width: $large) {
    margin-top: $blank-line / 1.5;
  }
}

/*
.slider {
  @media (min-width: $large) {
    :global(.slider-list) {
      display: flex;
      flex-wrap: wrap;
      margin-left: -$gutter-large;
    }

    :global(.slider-item) {
      margin-bottom: $gutter-large;
      padding-left: $gutter-large;
      width: 33.3334%;

      img {
        //filter: grayscale(1);
      }
    }
  }
}
*/
</style>
