<template>
  <div :class="$style.view" v-if="page.data">
    <header :class="$style.header">
      <h2 :class="$style.title" v-html="page.title" />
      <router-link :to="rootRoute" :class="$style.close" />
    </header>
    <BaseBodytext :text="page.data.body" :class="$style.body" />
    <vue-form-generator
      :schema="schema"
      :model="model"
      :options="formOptions"
    ></vue-form-generator>
  </div>
</template>

<script>
import BaseBodytext from '@/components/base-bodytext'
import { mapState } from 'vuex'
import axios from 'axios'
import VueFormGenerator from 'vue-form-generator'

export default {
  components: {
    BaseBodytext,
    'vue-form-generator': VueFormGenerator.component
  },
  data() {
    return {
      page: [],
      loading: false,
      error: null,
      model: {
        age_level: 'age_level',
        subject: 'subject'
        /*
        id: 1,
        name: 'John Doe',
        password: 'J0hnD03!x4',
        skills: ['Javascript', 'VueJS'],
        email: 'john.doe@gmail.com',
        status: true
        */
      },
      schema: {
        groups: [
          {
            legend: 'General',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: 'School'
                //model: 'school'
              },
              {
                type: 'checklist',
                listBox: true,
                label: 'Subject',
                model: 'subject',
                values: () => {
                  return this.filter[1].data.map(item => {
                    return {
                      name: item.title,
                      value: item.id
                    }
                  })
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'City'
                //model: 'city'
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Country'
                //model: 'country'
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Architect'
                //model: 'architect'
              }
            ]
          },
          {
            legend: 'Schedule of accommodation',
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: 'Amount of students'
                //model: ''
              },
              {
                type: 'radios',
                label: 'Age level',
                model: 'age_level',
                values: () => {
                  return this.filter[2].data.map(item => {
                    return {
                      name: item.title,
                      value: item.id
                    }
                  })
                },
                radiosOptions: {
                  //value: '',
                  //name: 'age_level'
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Total area'
                //model: ''
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Open area'
                //model: ''
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Footprint area'
                //model: ''
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Gross floor area'
                //model: ''
              },
              {
                type: 'input',
                inputType: 'text',
                label: 'Classrooms/Workshops'
                //model: ''
              }
            ]
          },
          {
            legend: 'Upload',
            fields: [
              {
                type: 'upload',
                label: 'Site plan'
                //model: ''
              },
              {
                type: 'upload',
                label: 'Floor plan (between scales 1:20 and 1:50)'
                //model: ''
              },
              {
                type: 'upload',
                label: 'Sectional drawing (between scales 1:2 and 1:50)'
                //model: ''
              },
              {
                type: 'upload',
                label: 'Detail drawing (between scales 1:2 and 1:20)'
                //model: ''
              },
              {
                type: 'upload',
                label: 'Description (max 1200 Words)'
                //model: ''
              },
              {
                type: 'submit',
                buttonText: 'Submit (disabled)',
                //model: 'id',
                disabled: true
              }
            ]
          }
        ]
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
        validateAsync: true
      }
    }
  },
  computed: {
    ...mapState(['filter']),
    rootRoute() {
      return this.$route.meta.root ? this.$route.meta.root.url : '/'
    }
  },
  methods: {
    fetchData() {
      this.page = []
      this.loading = true
      this.error = null
      axios
        .get(`page/${this.$route.meta.id}`)
        .then(response => {
          this.page = response.data
          this.loading = false
        })
        .catch(error => {
          alert(error)
        })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss">
.vue-form-generator {
  @extend %fs-large-body;

  fieldset {
    position: relative;
    padding: 0;
    padding-top: $gutter * 3;
    margin: 0;
    border: none;
    @include page-gutter(margin, top);
    //@include page-gutter(padding, top);
    @include border-regular(top);

    @media (min-width: $large) {
      padding-top: $gutter;
      padding-left: $page-indent;
    }
  }

  legend {
    position: absolute;
    top: $gutter / 2;
    left: 0;
    //@include page-gutter(padding, top);
    display: block;
    //padding-top: 0; // Override

    @media (min-width: $large) {
      top: $gutter;
      max-width: $page-indent;
      margin-right: 2.7rem;
    }
  }

  .form-group {
    margin-bottom: 0.75em;

    &.field-submit {
      margin-top: 2.25em;
    }
  }

  .field-wrap {
    margin-top: 0.25em;
  }

  input {
    &[type='text'],
    &[type='email'] {
      width: 100%;
      padding: 1em 0.5em;
      border: none;

      &:focus {
        outline: 0;
        //background-color: darken(white, 3%);
      }
    }

    &[type='submit'] {
      width: 100%;
      padding: 1em;
      color: white;
      background: $grey-text;
      border: none;
      appearance: none;
    }
  }

  .listbox,
  .radio-list {
    padding: 0.75em 1em;
    background-color: white;

    label {
      display: block;
      //@include input-icon($unchecked: '\e003', $checked: '\e002', $indent: 1.5em);
    }

    input {
      margin-right: 0.5em;
      vertical-align: middle;
    }
  }

  .field-upload {
    .field-wrap {
      padding: 1em;
      background-color: white;
    }
  }
}
</style>

<style lang="scss" module>
.view {
  @include page-gutter(padding, right, left);
  @include page-gutter-extended(padding, right, left);
  @include page-gutter-vertical(padding, top, bottom);

  position: relative;
  z-index: 2;
  min-height: 100%;
  margin-top: var(--panel-bar-height);
  background-color: $grey-fill;

  @media (min-width: $large) {
    margin-top: 0;
    margin-left: var(--panel-bar-height);
  }
}

.header {
  @extend %header-margin;

  display: flex;
  justify-content: space-between;
}

.title {
  @extend %fs-page-title;
}

.close {
  margin-left: 1em;

  &::after {
    @extend %ff-symbols;
    @extend %fs-page-title;

    display: inline-block;
    content: '\e001';
  }
}

.body {
  @extend %fs-large-body;
  @extend %body-field;
}
</style>
