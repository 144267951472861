<template>
  <div :class="$style.component">
    <ul :class="$style.nav">
      <li>
        <router-link :to="{ name: 1055 }">
          Contact
        </router-link>
      </li>
      <li>
        <a
          href="https://www.instagram.com/montessori_architecture/"
          rel="noopener"
          target="_blank"
        >
          Instagram
        </a>
      </li>
    </ul>

    <PartnerLogos :class="$style.logos" />
  </div>
</template>

<script>
import PartnerLogos from '@/components/partner-logos'

export default {
  components: { PartnerLogos }
}
</script>

<style lang="scss" module>
.component {
  margin-top: $blank-line * 6;

  @media (min-width: $xsmall) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

.nav {
  display: flex;
  margin-bottom: $blank-line;

  li {
    &:not(:last-child) {
      margin-right: 1em;
    }
  }

  a {
    @extend %hover-link;

    &::after {
      @extend %ff-symbols;

      position: relative;
      top: -0.25em;
      font-size: 0.8em;
      content: '\e015';
    }
  }

  @media (min-width: $xsmall) {
    margin-right: $gutter * 3;
    margin-bottom: 0;
  }
}

.logos {
  flex: 1;
  width: 75%;
  max-width: 38rem;

  @media (min-width: $xsmall) {
    width: 100%;
  }
}
</style>
