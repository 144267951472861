<template>
  <div :class="$style.view" v-if="page.data">
    <!-- <header :class="$style.header">
      <h2 :class="$style.title" v-html="page.title" />
    </header> -->

    <ToolHeader :page="page" />

    <content-rows :class="$style.content" :rows="page.data.content_rows" />

    <!-- <order-form /> -->
  </div>
</template>

<script>
import ContentRows from '@/components/content-rows'
// import OrderForm from '@/components/order-form'
import ToolHeader from '@/components/tool-header'
import axios from 'axios'

export default {
  // components: { ContentRows, OrderForm, ToolHeader },
  components: { ContentRows, ToolHeader },
  data() {
    return {
      page: [],
      loading: false,
      error: null
    }
  },
  methods: {
    fetchData() {
      this.page = []
      this.loading = true
      this.error = null
      axios
        .get(`page/${this.$route.meta.id}`)
        .then(response => {
          this.page = response.data
          this.loading = false
        })
        .catch(error => {
          alert(error)
        })
    }
  },
  created() {
    this.fetchData()
  }
}
</script>

<style lang="scss" module>
.view {
  @include page-gutter(padding, right, left);
  @include page-gutter-extended(padding, right, left);
  @include page-gutter-vertical(padding, top, bottom);
  // position: relative;
  // z-index: 2;
  // margin-top: var(--panel-bar-height);
  // min-height: 100%;
  // background-color: $white;

  // @media (min-width: $large) {
  //   margin-top: 0;
  //   margin-left: var(--panel-bar-height);
  // }
}

// .header {
//   @extend %header-margin;
//   display: flex;
//   justify-content: space-between;
// }

// .title {
//   @extend %fs-page-title;
// }

.content {
  margin-top: $blank-line;
}
</style>
