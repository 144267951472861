<template>
  <div :class="classes.component" v-if="slideCount">
    <div :class="$style.actions">
      <button
        v-if="!paginationVisible"
        @click="zoomOut"
        :class="$style.close"
        type="button"
      />
    </div>
    <div :class="$style.wrapper">
      <ul :class="$style.list" class="slider-list">
        <project-image-slide
          v-for="(item, index) in items"
          v-show="showSlide(index)"
          :item="item"
          :layout="layout"
          :key="item.id"
          :class="$style.item"
          :is-zoomed="isZoomed"
          @zoom-in="zoomIn(index)"
          class="slider-item"
        />
      </ul>
      <div :class="$style.nav">
        <button
          v-if="slideCount > 1"
          :class="classes.prev"
          @click="prev"
          v-hammer:swipe.left.right="onSwipe"
          type="button"
        ></button>
        <button
          :class="classes.zoom"
          @click="toggleZoom"
          v-hammer:swipe.left.right="onSwipe"
          type="button"
        ></button>
        <button
          v-if="slideCount > 1"
          :class="classes.next"
          @click="next"
          v-hammer:swipe.left.right="onSwipe"
          type="button"
        ></button>
      </div>
    </div>
    <div v-if="paginationVisible" :class="$style.pagination">
      <div v-if="title" v-html="title" />
      <div :class="$style.counter" v-html="counter" />
    </div>
  </div>
</template>

<script>
import ProjectImageSlide from '@/components/project-image-slide'
import EventBus from '@/event-bus'

export default {
  components: { ProjectImageSlide },
  props: {
    items: {
      type: Array,
      required: true
    },
    layout: {
      type: String,
      default: 'auto'
    },
    title: {
      type: String
    }
  },
  data() {
    return {
      currentSlide: 0,
      slideCount: this.items.length,
      isZoomed: false,
      paginationVisible: true,
      viewOffset: 0
    }
  },
  computed: {
    classes() {
      return {
        component: [
          this.$style.component,
          this.isZoomed ? 'is-zoomed' : 'not-zoomed',
          this.layout === 'grid' ? 'layout-grid' : ''
        ],
        prev: [this.$style.prev, this.firstSlide ? 'no-prev' : ''],
        next: [this.$style.next, this.lastSlide ? 'no-next' : ''],
        zoom: [this.$style.zoom]
      }
    },
    firstSlide() {
      return this.currentSlide === 0
    },
    lastSlide() {
      return this.currentSlide === this.slideCount - 1
    },
    counter() {
      return this.currentSlide + 1 + '&#8202;/&#8202;' + this.slideCount
    }
  },
  methods: {
    showSlide(index) {
      if (this.$mq === 'large' && this.layout === 'grid' && !this.isZoomed) {
        return true
      }
      return index === this.currentSlide
    },
    prev() {
      if (this.firstSlide) {
        this.currentSlide = this.slideCount - 1
      } else {
        this.currentSlide--
      }
    },
    next() {
      //if (this.lastSlide) {}
      this.currentSlide = (this.currentSlide + 1) % this.slideCount
    },
    onSwipe(event) {
      if (event.type === 'swiperight') {
        this.prev()
      } else if (event.type === 'swipeleft') {
        this.next()
      }
    },
    toggleZoom() {
      if (this.isZoomed) {
        this.zoomOut()
      } else {
        this.zoomIn()
      }
    },
    zoomIn(index) {
      // TODO: this is also triggered on <li> when .project-link is clicked (because of event bubbling)
      if (index) this.currentSlide = index
      this.setViewOffset()
      this.isZoomed = true
      this.paginationVisible = false
    },
    zoomOut() {
      this.isZoomed = false
      this.paginationVisible = true
      this.currentSlide = 0
    },
    setViewOffset() {
      const offset = this.$parent.$refs.view.getBoundingClientRect()
      this.viewOffset = offset.top
      //this.viewOffset = this.$parent.$refs.view.offsetTop
    }
  },
  watch: {
    isZoomed() {
      EventBus.$emit('slider-zoom', {
        isZoomed: this.isZoomed,
        viewOffset: this.viewOffset
      })
    }
  },
  deactivated() {
    // TODO: ugly workaround
    window.setTimeout(() => {
      this.isZoomed = false
    }, 500)
  }
}
</script>

<style lang="scss" module>
.component {
  position: relative;
  margin-bottom: $blank-line * 2.5;

  &:global(.is-zoomed) {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: $white;

    .wrapper {
      flex-grow: 1;
      // position: relative;
      // width: 100%;
      height: 100%;
      // flex: 1;
      min-height: 0;
      // height: 100%;
      // overflow: hidden;
      // flex: 1 1 100%;
    }

    .list {
      // width: 100%;
      height: 100%;
    }

    .item {
      display: flex;
      flex-direction: column;
      // align-items: center;
      height: 100%;
      // min-height: 100%;
    }

    img {
      // flex-grow: 1;
      // flex: 1;
      width: 100%;
      // max-width: 100%;
      height: 100%;
      // max-height: 100%;
      object-fit: contain;

      // --offset: calc(var(--slider-actions-height) / 2);

      // object-position: 0 calc(50% - var(--offset));
      object-position: 0 calc(50% - #{$gutter});
    }

    .zoom,
    .prev,
    .next {
      &::before {
        // Hide grey background when slider is open
        display: none;
      }
    }

    .zoom {
      display: none;
    }

    .prev,
    .next {
      // @extend %hover-link;
      // Always show arrows when slider is open
      opacity: 1;

      &::after {
        // Overwrite width to scale automatically
        width: auto;
      }
    }
  }

  &:global(.not-zoomed) {
    filter: grayscale(1);
    transition: filter 150ms ease;

    @media #{$mqHover} {
      &:hover {
        filter: grayscale(0);
      }
    }
  }

  @media (min-width: $large) {
    // margin-bottom: $blank-line;

    &:global(.is-zoomed) {
      // height: 100vh;
      background-color: $white;

      img {
        object-position: 0 50%;
      }
    }

    // &:global(.not-zoomed) {}

    &:global(.layout-grid.not-zoomed) {
      .list {
        display: flex;
        flex-wrap: wrap;
        margin-left: -$gutter;

        > * {
          width: 33.3333%;
          padding-left: $gutter;
          margin-bottom: $gutter;
        }
      }

      img {
        cursor: pointer;
        filter: grayscale(1);
        transition: filter 150ms ease;

        @media #{$mqHover} {
          &:hover {
            filter: grayscale(0);
          }
        }
      }

      .nav,
      .pagination {
        display: none;
      }
    }
  }
}

// .list {}

.actions {
  position: relative;
  // z-index: 2;
  text-align: right;

  @media (min-width: $large) {
    position: absolute;
    right: 0;
  }
}

.close {
  // @include page-gutter(padding, right, left);
  // @include page-gutter-vertical(padding, top, bottom);
  @extend %fs-slider-icon;
  // @extend %hover-link;

  // position: absolute;
  position: relative;
  z-index: 2;
  // width: auto;
  // display: inline-block;
  padding: $gutter;
  // top: 0;
  // right: 0;
  // color: $white;
  outline: 0;

  &::after {
    @extend %ff-symbols;

    display: inline-block;
    // @extend %fs-page-title;
    content: '\e001';
  }

  // @media #{$mqHover} {
  //   display: none;
  // }

  @media (min-width: $large) {
    top: -0.2em; // Optically align with image
  }
}

.nav {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  width: 100%;
  height: 100%;
  // cursor: pointer;

  > * {
    flex: 1;
  }

  :global(.is-zoomed) & {
    // Place arrows in top left corner
    width: auto;
    height: var(--slider-actions-height);
  }

  @media (min-width: $large) {
    // display: none;

    :global(.is-zoomed) & {
      // Move arrows back to original position
      width: 100%;
      height: 100%;
    }
  }
}

.prev,
.next,
.zoom {
  @extend %fs-slider-icon;

  position: relative;
  outline: 0;
  opacity: 0;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    // transform: translate(-50%, -50%);
    transform: translateY(-50%);
  }

  &::before {
    display: block;
    width: 1em;
    height: 1em;
    content: '';
    background-color: rgba($grey-fill, 0.9);
    border-radius: 50%;
  }

  &::after {
    @extend %ff-symbols;

    top: calc(50% + 0.05em); // Vertically align with background
    width: 1em;
  }
}

.prev,
.next {
  :global(.is-zoomed) & {
    margin-left: $gutter;

    &::after {
      position: static;
    }
  }

  @media (min-width: $large) {
    :global(.is-zoomed) & {
      margin-left: 0;

      &::after {
        position: absolute;
      }
    }
  }
}

.prev {
  &::before,
  &::after {
    left: $gutter;
  }

  &::after {
    content: '\e016';
  }
}

.next {
  &::before,
  &::after {
    right: $gutter;
  }

  &::after {
    content: '\e006';
  }
}

.zoom {
  &::before,
  &::after {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &::after {
    content: '\e00a';
  }
}

@media #{$mqHover} {
  .prev,
  .next,
  .zoom {
    display: block;
    opacity: 0;
    transition: opacity 100ms ease;

    &:hover {
      opacity: 1;
    }
  }
}

.pagination {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: $gutter / 2;
  margin-bottom: $blank-line * -1;

  @media (min-width: $large) {
    // display: none;
  }
}

.counter {
  margin-left: auto;
  user-select: none;
}
</style>
