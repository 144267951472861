export default {
  methods: {
    recallScrollPosition(container = window) {
      const pos = this.$store.state.scrollPositions
      const name = this.$route.name
      if (!pos[name]) return
      this.$nextTick(() => {
        container.scrollTo(0, pos[name].pos)
      })
    }
  }
}
