import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
//import main from './main'
import Home from '@/views/home.vue'
import BasicPage from '@/views/basic-page.vue'
import Contact from '@/views/contact.vue'
import Contribute from '@/views/contribute.vue'
import Order from '@/views/order.vue'
import Pattern from '@/views/pattern.vue'
import Patterns from '@/views/patterns.vue'
import Print from '@/views/print.vue'
import Project from '@/views/project.vue'
import Projects from '@/views/projects.vue'
import Tool from '@/views/tool.vue'

Vue.use(Router)
Vue.use(Meta)

/*
const scrollBehavior = function() {
  return { x: 0, y: 0 }
}
*/
/*
const scrollBehavior = (to, from, savedPosition) => {
  return new Promise(resolve => {
    main.$root.$once('scrollBeforeEnter', () => {
      if (savedPosition) {
        console.log('savedPosition')
        resolve(savedPosition)
      } else {
        console.log('0 0')
        resolve({ x: 0, y: 0 })
      }
    })
  })
}
*/

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  //scrollBehavior,
  routes: [
    {
      path: '/',
      component: Home
    }
  ]
})

export const addRoutesFromApi = routes => {
  routes = walkRoutes(routes)
  router.addRoutes(routes)

  // add wildcard route that redirects to 404 view
  router.addRoutes([
    {
      path: '/404',
      name: '404',
      component: require('@/views/_404').default,
      props: true
    },
    {
      path: '*',
      redirect: '404'
    }
  ])
}

function walkRoutes(routes) {
  routes.forEach(route => {
    route.component = BasicPage
    if (route.template === 'contact') route.component = Contact
    if (route.template === 'contribute') route.component = Contribute
    if (route.template === 'order') route.component = Order
    if (route.template === 'pattern') route.component = Pattern
    if (route.template === 'patterns') route.component = Patterns
    if (route.template === 'print') route.component = Print
    if (route.template === 'project') route.component = Project
    if (route.template === 'projects') route.component = Projects
    if (route.template === 'tool') route.component = Tool

    route.name = route.id

    route.path = route.url
    route.meta = {
      id: route.id,
      title: route.title,
      template: route.template
    }
    route.props = { id: route.id }

    if (route.root) route.meta.root = route.root

    if (route.parent) route.meta.parent = route.parent

    if (route.children) {
      let subnavigation = []
      route.children.forEach(child => {
        subnavigation.push(child)
      })

      route.meta.subnavigation = subnavigation

      routes = [...routes, ...walkRoutes(route.children, subnavigation)]

      delete route.children
    }
  })

  return routes
}

export default router
