<template>
  <div :class="classes.component">
    <selected-filter
      v-if="hasSelected && !isOpen"
      :class="classes.selected"
      :selection="filteredBy"
    />
    <div v-show="isOpen" :class="$style.wrapper">
      <filter-form v-if="isDesktop" :visible-filter="visibleFilter" />
      <portal v-else to="overlay" :disabled="!isOpen">
        <filter-form :visible-filter="visibleFilter" />
      </portal>
    </div>
  </div>
</template>

<script>
import FilterForm from '@/components/filter-form'
import SelectedFilter from '@/components/selected-filter'
import { mapState } from 'vuex'

export default {
  components: { FilterForm, SelectedFilter },
  props: {
    filter: {
      type: Array,
      required: true
    },
    clear: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      amountOfStudents: {
        id: 1,
        name: 'amount_of_students',
        title: 'Amount of students',
        data: [
          { id: 0, value: '0,99', title: '< 100' },
          { id: 1, value: '100,299', title: '100-300' },
          { id: 2, value: '300,599', title: '300-600' },
          { id: 3, value: '600,1199', title: '600-1200' },
          { id: 4, value: '1200,99999', title: '> 1200' }
        ]
      }
    }
  },
  computed: {
    ...mapState(['isDesktop', 'filteredBy']),
    classes() {
      return {
        component: [
          this.$style.component,
          this.isOpen ? this.$style['is-open'] : ''
        ],
        selected: [
          this.$style.selected,
          this.isOpen ? this.$style['is-open'] : ''
        ]
      }
    },
    visibleFilter() {
      let array = this.filter.slice()
      array.splice(2, 0, this.amountOfStudents)
      return array
    },
    hasSelected() {
      return (
        Object.keys(this.filteredBy).length > 0 &&
        this.filteredBy.constructor === Object
      )
    }
  }
}
</script>

<style lang="scss" module>
.component {
  @include border-regular(top);

  position: relative;
  z-index: 1;
  margin-bottom: $blank-line * 1.5;
}

.wrapper {
  visibility: hidden;
  opacity: 0;
  transition: opacity 150ms ease, visibility 150ms ease;

  .is-open & {
    visibility: visible;
    opacity: 1;
  }

  @media (min-width: $large) {
    // NOTE: override mobile styles if nav was closed
    // opacity: 1;
    // visibility: visible;
    // transition: none;
    @include border-regular(bottom);

    padding-top: $gutter / 2;
  }
}
</style>
