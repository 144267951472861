<template>
  <div v-if="rows.length" :class="$style.component">
    <content-row
      v-for="(row, index) in rows"
      :item="row"
      :key="`row-${index}`"
    />
  </div>
</template>

<script>
import ContentRow from '@/components/content-row'

export default {
  components: {
    ContentRow
  },
  props: {
    rows: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
// .component {}
</style>
