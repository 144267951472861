<template>
  <li :class="$style.component">
    <BaseLink
      :key="route.name"
      :to="route"
      :class="$style.link"
      :exact-active-class="$style.active"
      @click.native="clicked"
    >
      {{ routeTitle }}
    </BaseLink>
  </li>
</template>

<script>
import BaseLink from '@/components/base-link'

export default {
  components: { BaseLink },
  props: {
    route: {
      type: Object,
      required: true
    }
  },
  computed: {
    routeTitle() {
      return typeof this.route.title === 'function'
        ? this.route.title()
        : this.route.title
    }
  },
  methods: {
    clicked() {
      this.$emit('item-clicked')
    }
  }
}
</script>

<style lang="scss" module>
.component {
  &:not(:last-child) {
    margin-right: 0.7em;
  }
}

.link {
  // @extend %hover-link;
  white-space: nowrap;
  color: $grey-fill;

  @media (min-width: $medium) {
    color: $grey-text;
    transition: color 100ms ease;

    &:hover {
      color: $black;
    }

    &.active {
      color: $black;
      cursor: default;
    }
  }
}
</style>
