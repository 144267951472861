<template>
  <div :class="$style.component">
    <ul :class="$style.categories">
      <li
        v-for="(category, index) in projectsByCategory"
        :key="`category-${index}`"
        :class="$style.category"
      >
        <div :class="$style['category-title']" v-html="category.title" />
        <ul :class="$style.list">
          <ProjectsListItem
            v-for="project in category.items"
            :key="project.id"
            :project="project"
            :class="$style.item"
          />
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import ProjectsListItem from '@/components/projects-list-item'

export default {
  components: { ProjectsListItem },
  props: {
    projects: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },
  computed: {
    projectsByCategory() {
      let pbc = {}
      this.projects.forEach(project => {
        const catId = project.climate_zone
        const cat = this.categories.find(category => category.id === catId)

        // Check if category was already added to pbc
        if (!(catId in pbc)) {
          pbc[catId] = {
            title: cat.title,
            items: []
          }
        }

        // Add project to category
        pbc[catId].items.push(project)
      })
      return pbc
    }
  }
}
</script>

<style lang="scss" module>
.category {
  &:not(:first-of-type) {
    @include border-regular(top);

    margin-top: $blank-line * 1.5;

    .category-title {
      margin-top: $blank-line / 3;
    }
  }
}

.category-title {
  @extend %fs-pattern-title;

  margin-bottom: $blank-line / 3;
}

.list {
  // position: relative;
  // top: -$gutter;

  // @media (min-width: $large) {
  //   top: -$gutter-large;
  // }

  @media (min-width: $medium) {
    display: flex;
    flex-wrap: wrap;
    margin-left: $gutter * -2;

    > * {
      width: 50%;
      padding-left: $gutter * 2;
    }
  }

  // @media (min-width: $xxlarge) {
  //   > * {
  //     width: 33.333%;
  //   }
  // }
}

// .item {
//   &:last-of-type {
//     min-height: 100vh;
//   }
// }
</style>
