import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { addRoutesFromApi } from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    initialized: false,
    routes: [],
    pageTitle: undefined,
    pageSubtitle: undefined,
    metaDescription: undefined,
    coverImages: undefined,
    scrollPositions: {},
    filter: [],
    filteredBy: {},
    breakpoints: {
      large: window.matchMedia('(min-width: 64em)')
    },
    isDesktop: false
  },

  mutations: {
    set(state, data) {
      Object.entries(data).forEach(([key, value]) => {
        state[key] = value
      })
    },
    setScrollPosition(state, payload) {
      state.scrollPositions[payload.name] = payload
    },
    updateFilter(state, payload) {
      Vue.set(state.filteredBy, payload.name, payload)
    },
    clearFilter(state, payload) {
      if (payload && payload.name) {
        Vue.delete(state.filteredBy, payload.name)
      } else {
        state.filteredBy = {}
      }
    },
    updateBreakpoints(state) {
      // Use this function to test if a certain breakpoint matches and to define what should happen next
      state.isDesktop = state.breakpoints.large.matches
    }
  },

  actions: {
    async getDefaults({ commit }) {
      const { data } = await axios.get('defaults')
      commit('set', data)
      commit('set', { initialized: true })

      addRoutesFromApi(data.routes)
    },
    // Set up handlers for all defined breakpoints
    initBreakpoints({ commit, state }) {
      Object.keys(state.breakpoints).forEach(key => {
        // Check breakpoint initially
        // commit('updateBreakpoints', state.breakpoints[key])
        commit('updateBreakpoints')
        // Add event listener to breakpoint
        state.breakpoints[key].addListener(() => {
          commit('updateBreakpoints')
        })
      })
    }
  }
})
