import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import PortalVue from 'portal-vue'
import { VueHammer } from 'vue2-hammer'
import VueMq from 'vue-mq'

Vue.config.productionTip = false

axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.use(PortalVue)
Vue.use(VueHammer)

Vue.use(VueMq, {
  breakpoints: {
    /*
    xxsmall: 320,
    xsmall: 480,
    small: 640,
    medium: 768,
    large: 1024,
    xlarge: 1280,
    xxlarge: 1440,
    lg: Infinity
    */
    small: 1024,
    large: Infinity
  }
  //defaultBreakpoint: 'large' // customize this for SSR
})

// get api routes and init data before everything else:
router.beforeEach(async (to, from, next) => {
  if (store.state.initialized) {
    return next()
  }
  await store.dispatch('getDefaults')
  next({
    path: to.fullPath,
    replace: true
  })
})

const main = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

export default main
