<template>
  <li :class="$style.item" :id="`project-${project.id}`">
    <router-link :to="project.url">
      <BaseImage
        v-if="project.cover_image"
        :image="project.cover_image"
        :sizes="sizes"
        :class="$style.image"
      />
      <div :class="$style.title">
        {{ project.title }}<br />
        {{ project.location }}
      </div>
    </router-link>
  </li>
</template>

<script>
import BaseImage from '@/components/base-image'

export default {
  components: { BaseImage },
  props: {
    project: {
      type: Object,
      required: true
    }
  },
  computed: {
    sizes() {
      // eslint-disable-next-line
      return '(min-width: 1680px) 42.62vw, (min-width: 1024px) 41.5vw, calc(100vw - 3rem)'
    }
  }
}
</script>

<style lang="scss" module>
.item {
  // @include page-gutter(padding, top);
  margin-bottom: $blank-line;
}

.image {
  margin-bottom: $gutter / 4;
  filter: grayscale(1);
  transition: filter 150ms ease;

  @media #{$mqHover} {
    &:hover {
      filter: grayscale(0);
    }
  }

  @media (min-width: $large) {
    margin-bottom: $gutter / 2;
  }
}

// .title {}
</style>
