<template>
  <div :class="$style.component">
    <h3 :class="$style.title">Recent Projects</h3>
    <ul :class="$style.list">
      <ProjectsListItem
        v-for="project in projects"
        :key="project.id"
        :project="project"
        :class="$style.item"
      />
    </ul>
  </div>
</template>

<script>
import ProjectsListItem from '@/components/projects-list-item'

export default {
  components: { ProjectsListItem },
  props: {
    projects: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.component {
  @include border-regular(top);
  padding-top: $blank-line / 2;
}

.title {
  @extend %fs-column-title;
  margin-bottom: $blank-line / 2;
}

.list {
  @media (min-width: $medium) {
    display: flex;
    margin-left: $gutter * -1.5;

    > * {
      padding-left: $gutter * 1.5;
    }
  }
}
</style>
