<template>
  <div :class="$style.component">
    <button type="button" :class="$style.open" @click="toggle">Menu</button>
    <sub-nav-wrapper v-if="isDesktop" :routes="routes" @close-sub-nav="close" />
    <portal v-else to="overlay" :disabled="!isOpen">
      <sub-nav-wrapper
        :is-open="isOpen"
        :routes="routes"
        @close-sub-nav="close"
      />
    </portal>
  </div>
</template>

<script>
import SubNavWrapper from '@/components/sub-nav-wrapper'
import { mapState } from 'vuex'

export default {
  components: { SubNavWrapper },
  props: {
    routes: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    ...mapState(['isDesktop'])
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    close() {
      this.isOpen = false
    }
  }
}
</script>

<style lang="scss" module>
.component {
  @include border-regular(bottom);
  @extend %fs-sub-nav;

  padding-bottom: $gutter / 2;

  @media (min-width: $large) {
    @include border-regular(top);

    padding-top: $gutter / 2;
  }
}

.open {
  outline: 0;

  &::before {
    @extend %ff-symbols;

    padding-right: 0.5em;
    content: '\e017';
  }

  @media (min-width: $medium) {
    display: none;
  }
}
</style>
