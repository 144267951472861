<template>
  <div :class="$style.view" v-if="page.data" ref="view">
    <header :class="$style.header">
      <div :class="$style['category-title']">
        <span>{{ page.data.climate_zone.title }}</span>
      </div>
      <h2 :class="$style.title">
        <span v-html="page.title" />
        <span v-html="page.data.location" />
      </h2>
      <div>
        <router-link :to="rootRoute" :class="$style.close" />
      </div>
    </header>
    <tool-bar
      :item-id="page.id"
      :items="routes[2].meta.subnavigation"
      :page="page"
    />
    <section :class="$style.main">
      <div :class="$style.inner">
        <div :class="$style.images">
          <project-image-slider
            v-for="(slideshow, index) in page.data.slideshows"
            :key="`slideshow-${index}`"
            :items="slideshow.project_images"
            :title="slideshow.title"
            :class="$style.slider"
          />
        </div>

        <div :class="$style.bodytext">
          <div :class="$style.meta">
            <ul>
              <li v-if="subject">
                <div>Subject</div>
                <div v-html="subject" />
              </li>
              <li v-if="page.data.designer">
                <div>Design</div>
                <div v-html="page.data.designer" />
              </li>
              <li v-if="page.data.year">
                <div>Project year</div>
                <div v-html="page.data.year" />
              </li>
            </ul>
            <ul>
              <li v-if="page.data.climate_zone">
                <div>Climate zone</div>
                <div v-html="page.data.climate_zone.title" />
              </li>
              <li v-if="page.data.amount_of_students">
                <div>Amount of students</div>
                <div v-html="page.data.amount_of_students" />
              </li>
              <li v-if="page.data.age_level.title">
                <div>Age level</div>
                <div v-html="page.data.age_level.title" />
              </li>
            </ul>
            <ul>
              <li v-if="page.data.total_area">
                <div>Total area</div>
                <div
                  v-html="area(page.data.total_area)"
                  :class="$style.right"
                />
              </li>
              <li v-if="page.data.open_area">
                <div>Open area</div>
                <div v-html="area(page.data.open_area)" :class="$style.right" />
              </li>
              <li v-if="page.data.footprint_area">
                <div>Footprint area</div>
                <div
                  v-html="area(page.data.footprint_area)"
                  :class="$style.right"
                />
              </li>
              <li v-if="page.data.gross_floor_area">
                <div>Gross floor area</div>
                <div
                  v-html="area(page.data.gross_floor_area)"
                  :class="$style.right"
                />
              </li>
              <li>
                <div>Classrooms&#8202;/&#8202;Workshops</div>
                <div :class="$style.right">
                  {{ page.data.classrooms }}&#8202;/&#8202;{{
                    page.data.workshops
                  }}
                </div>
              </li>
            </ul>
            <ul v-if="page.data.plans[0]" :class="$style.plans">
              <li>
                <a
                  :href="page.data.plans[0].url"
                  :download="page.data.plans[0].basename"
                  target="_blank"
                >
                  Download plans
                </a>
              </li>
            </ul>
          </div>

          <base-bodytext :text="page.data.body" :class="$style.body" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import BaseBodytext from '@/components/base-bodytext'
import ProjectImageSlider from '@/components/project-image-slider'
import ToolBar from '@/components/tool-bar'
import { mapState } from 'vuex'
import EventBus from '@/event-bus'
import axios from 'axios'

export default {
  components: { BaseBodytext, ProjectImageSlider, ToolBar },
  data() {
    return {
      page: [],
      loading: false,
      error: null,
      sliderState: {}
    }
  },
  computed: {
    ...mapState(['routes']),
    /*
    classes() {
      return {
        view: [
          this.$style.view,
          this.sliderState.isZoomed ? 'slider-zoomed' : ''
        ]
      }
    },
    */
    rootRoute() {
      return this.$route.meta.root ? this.$route.meta.root.url : '/'
    },
    subject() {
      let titles = []
      this.page.data.subject.forEach(item => {
        titles.push(item.title)
      })
      return titles.join(', ')
    }
  },
  methods: {
    area(number) {
      return number + '&hairsp;m²'
    },
    fetchData() {
      this.page = []
      this.loading = true
      this.error = null
      axios
        .get(`page/${this.$route.meta.id}`)
        .then(response => {
          this.page = response.data
          this.loading = false
        })
        .catch(error => {
          alert(error)
        })
    },
    lockView() {
      this.$refs.view.style.position = 'fixed'
      this.$refs.view.style.top = this.sliderState.viewOffset + 'px'
    },
    releaseView() {
      this.$refs.view.style.position = ''
      this.$refs.view.style.top = ''
    },
    scrollView() {
      const view = document.getElementById('projects-view')
      this.$nextTick(() => {
        view.scrollTo(0, this.sliderState.viewOffset * -1)
      })
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    EventBus.$on('slider-zoom', payload => {
      this.sliderState = payload

      if (!this.sliderState.isZoomed) {
        this.releaseView()
        this.scrollView()
      } else if (this.sliderState.isZoomed && this.sliderState.viewOffset) {
        this.lockView()
      }
    })
  }
}
</script>

<style lang="scss" module>
.view {
  @include page-gutter(padding, right, left);
  @include page-gutter-extended(padding, right, left);
  @include page-gutter-vertical(padding, top, bottom);

  position: relative;
  // z-index: 2;
  min-height: 100%;
  // margin-top: var(--panel-bar-height);
  background-color: $white;

  @media (min-width: $large) {
    // margin-top: 1rem;
    // margin-bottom: 10rem;
    // margin-left: var(--panel-bar-height);
  }
}

.header {
  @extend %header-margin;

  display: flex;
  margin-left: $gutter * -2;

  > * {
    padding-left: $gutter * 2;
  }
}

.category-title {
  @extend %fs-pattern-title;

  display: none;

  @media (min-width: $large) {
    display: block;
    width: 50%;
  }
}

.title {
  @extend %fs-pattern-title;

  flex: 1;

  span {
    display: block;
  }
}

.close {
  // @extend %hover-link;

  margin-left: 1em;

  &::after {
    @extend %ff-symbols;
    @extend %fs-pattern-title;

    display: inline-block;
    content: '\e001';
  }

  @media (min-width: $xxlarge) {
    position: relative;
    left: $gutter * 3.2;
  }
}

.main {
  @include border-regular(top);
  @include page-gutter-vertical(padding, top);
}

.inner {
  @media (min-width: $large) {
    display: flex;
    align-items: flex-start;
    margin-left: $gutter * -2;

    > * {
      flex: 1;
      padding-left: $gutter * 2;
    }
  }
}

.images {
  @media (min-width: $large) {
    // order: 1;
  }
}

// .slider {
//   @media (min-width: $large) {
//     :global(.slider-item) {
//       margin-bottom: $gutter-large;
//     }
//   }
// }

.bodytext {
  @media (min-width: $large) {
    position: relative;
    top: -0.5rem; // Optically align with images
  }
}

.meta {
  margin-bottom: $blank-line * 2;

  ul {
    @include border-regular(top);

    padding-top: $gutter / 2;
    margin-top: $gutter / 2;
  }

  @media (min-width: $xxsmall) {
    li {
      display: flex;
      margin-left: $gutter / -2;

      > * {
        width: 50%;
        padding-left: $gutter / 2;
      }
    }
  }

  @media (min-width: $large) {
    ul {
      padding-top: $gutter-large / 2;
      margin-top: $gutter-large / 2;

      &:first-child {
        padding-top: 0;
        margin-top: 0;
        border-top: none;
      }
    }
  }
}

.right {
  @media (min-width: $xxsmall) {
    text-align: right;
  }
}

.plans {
  margin-bottom: $blank-line;

  a {
    &::after {
      @extend %ff-symbols;

      display: inline-block;
      margin-left: 0.25em;
      content: '\e010';
    }
  }
}

.body {
  @extend %body-field;
}
</style>
