<template>
  <div :class="$style.view" v-if="page.data">
    <header :class="$style.header">
      <h2 :class="$style.title" v-html="page.title" />
    </header>
    <section :class="$style.main">
      <div :class="$style['tool-bar']">
        <ul :class="$style['nav-bar']">
          <li :class="[viewMode === 'preview' ? $style.active : '']">
            <button @click="viewMode = 'preview'" type="button">
              Preview
            </button>
          </li>
          <li :class="[viewMode === 'map' ? $style.active : '']">
            <button @click="viewMode = 'map'" type="button">
              Map
            </button>
          </li>
          <li :class="[viewMode === 'index' ? $style.active : '']">
            <button @click="viewMode = 'index'" type="button">
              Index
            </button>
          </li>
        </ul>
        <button :class="classes.toggle" @click="toggleFilter" type="button">
          Filter
        </button>
      </div>

      <ProjectsFilter :filter="filter" :is-open="filterVisible" ref="filter" />

      <div>
        <ProjectsList
          v-if="viewMode === 'preview'"
          :projects="filteredProjects"
          :categories="page.climateZones"
        />
        <ProjectsIndex
          v-if="viewMode === 'index'"
          :projects="filteredProjects"
          :categories="page.climateZones"
        />
        <div v-if="showMap" :class="$style.map">
          <mapbox
            @map-load="mapLoaded"
            :access-token="mapAccessToken"
            :map-options="mapOptions"
            :nav-control="navControl"
          >
          </mapbox>
        </div>

        <div v-if="!filteredProjects.length" :class="$style.empty">
          No results
        </div>
      </div>
    </section>

    <FooterNav :class="$style.footer" />
  </div>
</template>

<script>
import FooterNav from '@/components/footer-nav'
import ProjectsFilter from '@/components/projects-filter'
import ProjectsList from '@/components/projects-list'
import ProjectsIndex from '@/components/projects-index'
import RecallScrollPosition from '@/mixins/recall-scroll-position.js'
import { mapState } from 'vuex'
import axios from 'axios'
import EventBus from '@/event-bus'
import Mapbox from 'mapbox-gl-vue'
// import VueScrollTo from 'vue-scrollto'

export default {
  components: {
    FooterNav,
    Mapbox,
    ProjectsFilter,
    ProjectsList,
    ProjectsIndex
  },
  mixins: [RecallScrollPosition],
  data() {
    return {
      page: [],
      loading: false,
      error: null,
      scrollOffset: 0,
      viewMode: 'preview',
      filterVisible: false,
      initMap: false,
      // eslint-disable-next-line
      mapAccessToken:
        'pk.eyJ1IjoibmFkaW5ld3VldGhyaWNoIiwiYSI6ImNqdzY4bTB1cjAyaGg0YXFoeTNnb241eTcifQ.ynQ_4-z54NtQuKHlxphvnw',
      mapOptions: {
        style: 'mapbox://styles/nadinewuethrich/cjvi1yfc002pj1cmsha287ouh',
        center: [0, 0],
        zoom: 1,
        attributionControl: false,
        logoPosition: 'top-right'
      },
      navControl: {
        show: true,
        position: 'bottom-right',
        options: {
          showCompass: false // TODO: not working, CSS workaround in place
        }
      }
    }
  },
  computed: {
    ...mapState(['filter', 'filteredBy']),
    classes() {
      return {
        toggle: [
          this.$style['filter-toggle'],
          this.filterVisible ? this.$style['is-open'] : ''
        ]
      }
    },
    filteredProjects() {
      if (!this.page.data) return
      const filtered = this.page.data.filter(item => {
        let passed = true
        if (this.filteredBy.subject) {
          passed = item.subject.includes(Number(this.filteredBy.subject.value))
          if (!passed) return false
        }
        if (this.filteredBy.climate_zone) {
          passed =
            Number(this.filteredBy.climate_zone.value) === item.climate_zone
          if (!passed) return false
        }
        if (this.filteredBy.amount_of_students) {
          const range = this.filteredBy.amount_of_students.value.split(',')
          passed =
            item.amount_of_students >= range[0] &&
            item.amount_of_students <= range[1]
          if (!passed) return false
        }
        if (this.filteredBy.age_level) {
          if (!item.age_level) return
          const ageLevels = item.age_level.map(age => age.id)
          passed = ageLevels.includes(Number(this.filteredBy.age_level.value))
          if (!passed) return false
        }
        return passed
      })
      return filtered
    },
    isFiltered() {
      return this.page.data.length !== this.filteredProjects.length
    },
    showMap() {
      return this.$mq === 'large' && this.initMap && this.viewMode === 'map'
    },
    markers() {
      let markers = this.page.geoJson
      markers.features.forEach(item => {
        const included = this.filteredProjects.some(el => {
          return el.id === item.id
        })
        const state = included ? true : false
        item.properties = {
          active: String(state)
        }
      })
      return markers
    }
  },
  methods: {
    fetchData() {
      this.page = []
      this.loading = true
      this.error = null
      axios
        .get(`projects`)
        .then(response => {
          this.page = response.data
          this.loading = false
        })
        .catch(error => {
          alert(error)
        })
    },
    toggleFilter() {
      this.filterVisible = !this.filterVisible
    },
    closeFilter() {
      this.filterVisible = false
    },
    mapLoaded(map) {
      this.map = map

      this.map.addLayer({
        id: 'projects',
        type: 'circle',
        source: {
          type: 'geojson',
          data: this.markers
        },
        paint: {
          'circle-radius': 10,
          'circle-stroke-width': 2,
          'circle-stroke-color': 'rgb(0, 0, 0)',
          'circle-color': [
            'match',
            ['get', 'active'],
            // eslint-disable-next-line
            'true',
            'rgb(0, 0, 0)',
            'rgba(0, 0, 0, 0)' // fallback
          ]
        }
      })

      this.map.on('click', 'projects', event => {
        const project = event.features[0]

        // Fly to project on map
        // this.map.flyTo({
        //   center: event.features[0].geometry.coordinates,
        //   //zoom: 9,
        //   speed: 0.5
        //   //curve: 1
        // })

        // If projects are filtered...
        // if (this.isFiltered) {
        //   const projectIncluded = this.filteredProjects.some(el => {
        //     return el.id === project.id
        //   })
        //   // ...and clicked project is not part of filtered projects...
        //   if (!projectIncluded) {
        //     // ...then clear filter
        //     this.$refs.filter.clearForm()
        //   }
        // }

        // Scroll to project in project list
        // this.$nextTick(() => {
        //   this.scrollTo('project-' + project.id)
        // })

        // Go to clicked project
        this.$router.push({ name: project.id })
      })

      this.map.on('mouseenter', 'projects', () => {
        this.map.getCanvas().style.cursor = 'pointer'
      })

      this.map.on('mouseleave', 'projects', () => {
        this.map.getCanvas().style.cursor = ''
      })
    },
    updateMarkers() {
      if (this.map) this.map.getSource('projects').setData(this.markers)
    }
    // scrollTo(id) {
    //   VueScrollTo.scrollTo('#' + id, 1000, {
    //     container: '#projects-view',
    //     offset: this.scrollOffset
    //   })
    // }
  },
  created() {
    this.fetchData()
    this.map = undefined // Make map not reactive
  },
  mounted() {
    EventBus.$on('close-filter', this.closeFilter)
    EventBus.$on('after-enter', () => {
      this.$nextTick(() => {
        this.initMap = true
      })
    })
  },
  watch: {
    filteredProjects() {
      this.updateMarkers()
    }
  },
  activated() {
    this.recallScrollPosition(this.$parent.$refs.projectsView)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setScrollPosition', {
      name: from.name,
      pos: this.$parent.$refs.projectsView.scrollTop
    })
    next()
  }
}
</script>

<style lang="scss">
@import url('https://api.tiles.mapbox.com/mapbox-gl-js/v1.0.0/mapbox-gl.css');

.mapboxgl-map {
  position: relative;
  z-index: 0;
  width: 100%;
  // height: calc(
  //   100vh - #{$gutter-large * 3} - 6.5rem
  // ); // 6.5rem equals filter dropdown height
  height: calc(100vh - 32rem); // TODO: temp value
}

.mapboxgl-canvas {
  outline: 0;
}

.mapboxgl-marker {
  width: 1.5em;
  height: 1.5em;
  cursor: pointer;
  background-color: $black;
  border-radius: 50%;
}

a.mapboxgl-ctrl-logo {
  display: none; // TODO: temp value during exhibition
}

.mapboxgl-ctrl-icon {
  &.mapboxgl-ctrl-compass {
    display: none; // TODO: workaround
  }

  &.mapboxgl-ctrl-zoom-in,
  &.mapboxgl-ctrl-zoom-out {
    @extend %fs-slider-icon;

    position: relative;
    line-height: 1;
    background-image: none;
    outline: 0;

    &::before {
      display: block;
      width: 1em;
      height: 1em;
      content: '';
      background-color: rgba($grey-fill, 0.9);
      border-radius: 50%;
    }

    &::after {
      @extend %ff-symbols;

      position: absolute;
      top: 0.075em;
      left: 0;
      width: 1em;
    }
  }

  &.mapboxgl-ctrl-zoom-in {
    margin-bottom: 0.25em;
    &::after {
      content: '\e00a';
    }
  }

  &.mapboxgl-ctrl-zoom-out {
    &::after {
      content: '\e018';
    }
  }
}

.mapboxgl-ctrl-group {
  background-color: transparent;
  border-radius: 0;

  &:not(:empty) {
    box-shadow: none;
  }

  > button {
    width: auto;
    height: auto;

    + button {
      border-top: none;
    }

    &:hover {
      background-color: transparent;
    }
  }
}
</style>

<style lang="scss" module>
.view {
  @extend %view-padding;

  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.header {
  @extend %header-margin;

  display: none;

  @media (min-width: $large) {
    display: block;
  }
}

.title {
  @extend %fs-page-title;

  min-height: 2.1em;
}

// .main {
//   @media (min-width: $large) {
//     display: flex;
//     align-items: flex-start;
//     margin-left: -$gutter-large;

//     > * {
//       flex: 1;
//       padding-left: $gutter-large;
//     }

//     .filter {
//       order: 1;
//       position: sticky; // TODO: leads to scrolling complications when filter is taller than viewport
//       top: $gutter-large;
//     }
//   }
// }

.tool-bar {
  @extend %fs-filter-bar;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: $gutter / 2;

  @media (min-width: $large) {
    @include border-regular(top);

    padding-top: $gutter / 2;
  }
}

.nav-bar {
  display: none;

  li {
    @extend %hover-link;

    &.active {
      color: $black;
      cursor: default;

      button {
        cursor: inherit;
      }
    }
  }

  button {
    outline: 0;
  }

  @media (min-width: $large) {
    display: flex;

    li {
      &:not(:last-child) {
        margin-right: 0.7em;
      }
    }
  }
}

.filter-toggle {
  @extend %hover-link;

  outline: 0;

  &::after {
    @extend %ff-symbols;
    @extend %fs-filter-bar-icon;

    display: inline-block;
    padding-left: 0.3em;
    content: '\e004';
  }

  &.is-open {
    &::after {
      content: '\e005';
    }
  }
}

.empty {
  @extend %fs-large-body;
}

.footer {
  // margin-top: auto;
}
</style>
