<template>
  <div :class="$style.view" v-if="page.data">
    <!-- <header :class="$style.header">
      <h1 :class="$style.title" v-html="pageTitle" />
      <h2 :class="$style.subtitle" v-html="pageSubtitle" />
    </header>
    <div :class="$style.nav">
      <SubNav :routes="routes[0]" :class="$style.children" />
    </div> -->

    <ToolHeader :page="page" />

    <BaseBodytext :text="page.data.body" :class="$style.body" />

    <RecentProjects :projects="page.recent_projects" />

    <FooterNav />
  </div>
</template>

<script>
import BaseBodytext from '@/components/base-bodytext'
import FooterNav from '@/components/footer-nav'
import RecentProjects from '@/components/recent-projects'
import ToolHeader from '@/components/tool-header'
import RecallScrollPosition from '@/mixins/recall-scroll-position.js'
import axios from 'axios'

export default {
  components: { BaseBodytext, FooterNav, RecentProjects, ToolHeader },
  mixins: [RecallScrollPosition],
  data() {
    return {
      page: [],
      loading: false,
      error: null
    }
  },
  methods: {
    fetchData() {
      this.page = []
      this.loading = true
      this.error = null
      axios
        .get(`tool`)
        .then(response => {
          this.page = response.data
          this.loading = false
        })
        .catch(error => {
          alert(error)
        })
    }
  },
  created() {
    this.fetchData()
  },
  activated() {
    this.recallScrollPosition(this.$parent.$refs.toolView)
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit('setScrollPosition', {
      name: from.name,
      pos: this.$parent.$refs.toolView.scrollTop
    })
    next()
  }
}
</script>

<style lang="scss" module>
.view {
  @extend %view-padding;
}

.body {
  @extend %fs-tool-body;
  @extend %body-field;

  padding-top: 0.6em;
  // @include border-regular(top);
  margin-top: $gutter / 2;
  margin-bottom: 0.925em;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  // @media (min-width: $large) {
  //   margin-top: $gutter-large / 2;
  //   padding-top: $gutter-large / 2;
  // }
}
</style>
