<template>
  <div :class="classes.component">
    <button type="button" :class="$style.close" @click="close"></button>
    <ul :class="$style.list">
      <sub-nav-item :route="routes" :key="routes.name" @item-clicked="close" />
      <sub-nav-item
        v-for="route in routes.meta.subnavigation"
        :route="route"
        :key="route.name"
        @item-clicked="close"
      />
    </ul>
  </div>
</template>

<script>
import SubNavItem from '@/components/sub-nav-item'

export default {
  components: { SubNavItem },
  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
    routes: {
      type: Object,
      required: true
    }
  },
  computed: {
    classes() {
      return {
        component: [
          this.$style.component,
          this.isOpen ? this.$style['is-open'] : ''
        ]
      }
    }
  },
  methods: {
    close() {
      this.$emit('close-sub-nav')
    }
  }
}
</script>

<style lang="scss" module>
.component {
  @include page-gutter(padding, right, left);
  @include page-gutter-vertical(padding, top, bottom);
  @extend %fs-tool-nav;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  visibility: hidden;
  background-color: $black;
  opacity: 0;
  transition: opacity 150ms ease, visibility 150ms ease;
  -webkit-overflow-scrolling: touch;

  &.is-open {
    visibility: visible;
    opacity: 1;
  }

  @media (min-width: $medium) {
    position: static;
    z-index: initial;
    width: auto;
    height: auto;
    padding: 0;
    overflow: initial;
    visibility: visible;
    background-color: transparent;
    opacity: 1;
    transition: none;
  }
}

.close {
  display: block;
  margin-left: auto;
  color: $grey-fill;
  outline: 0;

  &::before {
    @extend %ff-symbols;

    content: '\e001';
  }

  @media (min-width: $medium) {
    display: none;
  }
}

.list {
  @media (min-width: $medium) {
    display: flex;
  }
}
</style>
