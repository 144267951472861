<template>
  <div :class="$style.component">
    <ul :class="$style.list">
      <li :class="$style.item" v-for="image in item.images" :key="image.id">
        <base-image :image="image" :sizes="sizes" />
        <div
          :class="$style.caption"
          v-if="image.description"
          v-html="image.description"
        ></div>
      </li>
    </ul>
  </div>
</template>

<script>
import BaseImage from '@/components/base-image'

export default {
  components: { BaseImage },
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    sizes() {
      // TODO: This is just a rough estimate as it does not respect column count and selected layout option
      // eslint-disable-next-line
      return '(min-width: 1024px) 50vw, calc(100vw - 3rem)'
    }
  }
}
</script>

<style lang="scss" module>
.component {
  margin-bottom: $blank-line;

  @media (min-width: $large) {
    margin-bottom: $blank-line * 2;
  }
}

.item {
  &:not(:last-child) {
    margin-bottom: $blank-line;
  }
}

.caption {
  @extend %fs-references;

  margin-top: 0.25em;
}
</style>
