<template>
  <li :class="$style.item" :id="`project-${project.id}`">
    <router-link :to="project.url" :class="$style.link">
      <div :class="$style.title">
        {{ project.title }}<br />
        {{ project.location }}
      </div>
    </router-link>
  </li>
</template>

<script>
export default {
  props: {
    project: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" module>
.item {
  @include border-regular(top);
  @extend %fs-pattern-title;

  padding-top: 0.5em;
  padding-bottom: 0.45em;

  @media (min-width: $large) {
    padding-top: 0.4em;
    padding-bottom: 0.35em;
  }
}

.link {
  display: flex;
  transition: color 100ms ease;

  &::after {
    @include page-gutter(margin, left);
    @extend %ff-symbols;

    display: flex;
    align-items: center;
    // @extend %fs-page-title;
    content: '\e011';
  }

  &:hover {
    color: $grey-border;
  }
}

.title {
  flex: 1;
}
</style>
