<template>
  <li :class="$style.component">
    <div :class="$style['group-title']">{{ group.title }}</div>
    <ul>
      <li v-for="item in group.data" :key="item.id">
        <label :class="$style.label">
          <input
            :name="group.id"
            :value="item.value ? item.value : item.id"
            v-model="checkedBoxes"
            type="checkbox"
            @input="clear"
          />
          <span v-html="item.title" />
        </label>
      </li>
    </ul>
  </li>
</template>

<script>
export default {
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      checkedBoxes: []
    }
  },
  methods: {
    clear() {
      // Only allow one active checkbox per group
      this.checkedBoxes = []
    }
  },
  watch: {
    checkedBoxes() {
      const value = this.checkedBoxes[0]
      const item = this.group.data.find(group => group.id === value)
      const itemTitle = item ? item.title : ''

      if (item) {
        this.$store.commit('updateFilter', {
          name: this.group.name,
          value: value,
          groupTitle: this.group.title,
          title: itemTitle
        })
      } else {
        this.$store.commit('clearFilter', {
          name: this.group.name
        })
      }
    }
  }
}
</script>

<style lang="scss" module>
.component {
  margin-bottom: 0.25em;
  color: $grey-fill;

  @media (min-width: $large) {
    flex: 1;
    padding-left: $gutter * 1.5;
    color: $black;

    &:nth-child(2) {
      flex: 1.8;
    }
  }
}

.group-title {
  @extend %fs-regular;

  @media (min-width: $large) {
    margin-bottom: 0.25em;
  }
}

.label {
  @include input-icon($unchecked: '\e003', $checked: '\e002', $indent: 1.1em);
}
</style>
