<template>
  <div v-if="pdfLink" :class="$style.component">
    <a :href="pdfLink" :class="$style.link" rel="noopener" target="_blank">
      <span :class="$style.label">Save</span>
      <span :class="$style.icon" />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    pdfLink() {
      return this.page.data.file && this.page.data.file.url
        ? this.page.data.file[0].url
        : false
    }
  }
}
</script>

<style lang="scss" module>
// .component {}

.link {
  @extend %hover-link;

  display: flex;
}

.label {
  @extend %fs-tool-bar;

  display: none;
  padding-right: 0.3em;

  @media (min-width: $large) {
    display: block;
  }
}

.icon {
  &::after {
    @extend %ff-symbols;
    @extend %fs-tool-bar-icon;

    display: inline-block;
    content: '\e012';
  }
}
</style>
