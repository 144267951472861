<template>
  <component :is="layout" :root-template="rootTemplate" v-if="pageInitialized">
    <RouterView
      :slot="rootTemplate"
      :key="$route.fullPath"
      :data-slot="rootTemplate"
    />
  </component>
</template>

<script>
import DefaultLayout from '@/layouts/default'
import { mapState } from 'vuex'

export default {
  components: { DefaultLayout },
  metaInfo() {
    return {
      titleTemplate: titleChunk => {
        return titleChunk ? `${this.pageTitle} – ${titleChunk}` : this.pageTitle
      },
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.metaDescription
        }
      ]
    }
  },
  data() {
    return {
      pageInitialized: false
    }
  },
  computed: {
    ...mapState(['initialized', 'pageTitle', 'metaDescription']),
    layout() {
      return this.$route.meta.layout
        ? `${this.$route.meta.layout}Layout`
        : 'DefaultLayout'
    },
    rootTemplate() {
      //console.log(this.$route)
      return this.$route.meta.root
        ? this.$route.meta.root.template
        : this.$route.meta.template
    }
  },
  watch: {
    initialized() {
      /*
      this.$nextTick(() => {
        this.pageInitialized = true
      })
      */
      this.pageInitialized = true
    }
  },
  created() {
    // Set up handlers for all defined breakpoints
    this.$store.dispatch('initBreakpoints')
  }
}
</script>

<style lang="scss">
@import '@/styles/defaults.scss';
</style>
