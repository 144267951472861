<template>
  <div :class="$style.component">
    <header :class="$style.header">
      <h1 :class="$style.title" v-html="pageTitle" />
      <h2 :class="$style.subtitle" v-html="pageSubtitle" />
    </header>
    <div :class="$style.nav">
      <sub-nav :routes="routes[0]" />
    </div>
  </div>
</template>

<script>
import SubNav from '@/components/sub-nav'
import { mapState } from 'vuex'

export default {
  components: { SubNav },
  props: {
    page: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState(['pageTitle', 'pageSubtitle', 'routes'])
  }
}
</script>

<style lang="scss" module>
.header {
  @extend %header-margin;

  display: none;

  @media (min-width: $large) {
    display: block;
  }
}

.title,
.subtitle {
  @extend %fs-page-title;
}

.nav {
  // @extend %header-margin;
  // margin-bottom: $blank-line * 1.5;
}

// .children {
//   @include border-regular(top);
//   padding-top: $gutter / 2;

//   @media (min-width: $large) {
//     padding-top: $gutter-large / 2;
//   }
// }
</style>
